import clsx from "clsx";
import dayjs from "dayjs";
import saveAs from "file-saver";
import { asBlob } from "html-docx-js-typescript";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Tooltip, TooltipContentContainer, TooltipTrigger } from "../../../../components/atoms/Tooltip/Tooltip";
import { TooltipContent } from "../../../../components/atoms/Tooltip/TooltipContent/TooltipContent";
import { guidRegex, isTextPartHTML } from "../../../../state/ChatContext";
import styles from "./Extra.module.scss";

type ExtraProps = {
	message: string;
	isHtml: boolean;
};

type FastTooltipProps = {
	isVisible: boolean;
	i18nKey: string;
	onClick?: () => void;
	children: React.ReactNode;
	childrenHover: React.ReactNode;
};
const FastTooltip = ({ isVisible, i18nKey, onClick, children, childrenHover }: React.PropsWithChildren<FastTooltipProps>) => {
	const { t } = useTranslation();

	const [isHover, setIsHover] = useState(false);

	if (!isVisible) {
		return null;
	}

	return (
		<Tooltip placement="bottom" offset={10}>
			<TooltipTrigger>
				<button
					title={t(i18nKey)}
					type="button"
					className={styles.extra}
					onClick={() => onClick?.()}
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
				>
					{isHover ? childrenHover : children}
				</button>
			</TooltipTrigger>
			<TooltipContentContainer>
				<TooltipContent title={t(i18nKey)} />
			</TooltipContentContainer>
		</Tooltip>
	);
};

export const Extra = ({ message, isHtml }: ExtraProps) => {
	const generateWordDocument = async () => {
		let allHtml = message;

		const utf8Metadata = "charset=utf-8";

		if (!allHtml.includes(utf8Metadata)) {
			allHtml = allHtml.replace("<head>", `<head><meta http-equiv="Content-Type" content="text/html; ${utf8Metadata}">`);
		}

		await asBlob(allHtml)
			.then((html) => {
				const blob = new Blob([html], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8" });
				saveAs(blob, `William Document ${dayjs().format("YYYY-MM-DD_HH-mm-ss")}.docx`);

				toast.success("Document downloaded");
			})
			.catch((error) => {
				//console.error("Error while exporting to docx: ", error);
			});
	};

	const copyHtmlAsTextToClipboard = () => {
		let allText = message;

		//get all content from body tag
		const bodyContent = allText.match(/<body[^>]*>[\s\S]*<\/body>/gi);
		allText = bodyContent ? bodyContent[0] : allText;

		//remove all html tags
		allText = allText.replace(/<[^>]*>/g, "");

		navigator.clipboard.writeText(allText);

		toast.success("Text copied to clipboard");
	};

	const copyTextToClipboard = () => {
		const allText = message;

		const textWithoutGuids = allText.replace(guidRegex, "");

		const textWithoutObjects = textWithoutGuids.replace(/\[.+\]/g, "");

		navigator.clipboard.writeText(textWithoutObjects);

		toast.success("Text copied to clipboard");
	};

	const [isHoverWord, setIsHoverWord] = useState(false);
	const [isHoverCopy, setIsHoverCopy] = useState(false);

	if (isHtml)
		return (
			<div className={clsx(styles.container, styles.html)}>
				<FastTooltip
					isVisible={isHtml}
					i18nKey="exportToWord"
					onClick={generateWordDocument}
					childrenHover={
						<svg width="12" height="14" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M.555 12.95h9.896a.54.54 0 0 1 .548.49.532.532 0 0 1-.474.555l-.074.005H.555a.54.54 0 0 1-.554-.49.532.532 0 0 1 .48-.556l.074-.004h9.896H.555zM5.418.005 5.504 0c.372 0 .684.266.728.618L6.237.7v8.81l2.415-2.304a.76.76 0 0 1 .968-.058l.069.059c.26.25.287.645.06.924l-.06.065-3.664 3.497a.76.76 0 0 1-.967.058l-.07-.058L1.32 8.196a.677.677 0 0 1-.034-.96.758.758 0 0 1 1.002-.088l.069.058L4.77 9.507V.7c0-.355.278-.654.647-.695L5.504 0l-.086.005z"
								fill="#323B4B"
								fillRule="nonzero"
							/>
						</svg>
					}
				>
					<svg width="12" height="14" viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M.555 12.95h9.896a.54.54 0 0 1 .548.49.532.532 0 0 1-.474.555l-.074.005H.555a.54.54 0 0 1-.554-.49.532.532 0 0 1 .48-.556l.074-.004h9.896H.555zM5.418.005 5.504 0c.372 0 .684.266.728.618L6.237.7v8.81l2.415-2.304a.76.76 0 0 1 .968-.058l.069.059c.26.25.287.645.06.924l-.06.065-3.664 3.497a.76.76 0 0 1-.967.058l-.07-.058L1.32 8.196a.677.677 0 0 1-.034-.96.758.758 0 0 1 1.002-.088l.069.058L4.77 9.507V.7c0-.355.278-.654.647-.695L5.504 0l-.086.005z"
							fill="#8A94A6"
							fillRule="nonzero"
						/>
					</svg>
				</FastTooltip>
				<FastTooltip
					isVisible={true}
					i18nKey="copyText"
					onClick={copyHtmlAsTextToClipboard}
					childrenHover={
						<svg width="12" height="15" xmlns="http://www.w3.org/2000/svg">
							<path
								d="m1.127 1.936-.002 1.486v7.35c0 1.257 1.091 2.275 2.438 2.275h6.461c-.238.63-.876 1.05-1.591 1.05h-4.87C1.595 14.097 0 12.61 0 10.773V3.422c0-.686.47-1.27 1.127-1.486zM10.312.097c.448 0 .877.166 1.194.461.316.296.494.696.494 1.114v9.098c0 .418-.178.818-.494 1.114a1.75 1.75 0 0 1-1.194.46h-6.75c-.931 0-1.687-.704-1.687-1.574V1.672c0-.87.756-1.575 1.688-1.575h6.75z"
								fill="#323B4B"
								fillRule="nonzero"
							/>
						</svg>
					}
				>
					<svg width="12" height="15" viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg">
						<path
							d="m1.127 1.936-.002 1.486v7.35c0 1.257 1.091 2.275 2.438 2.275h6.461c-.238.63-.876 1.05-1.591 1.05h-4.87C1.595 14.097 0 12.61 0 10.773V3.422c0-.686.47-1.27 1.127-1.486zM10.312.097c.448 0 .877.166 1.194.461.316.296.494.696.494 1.114v9.098c0 .418-.178.818-.494 1.114a1.75 1.75 0 0 1-1.194.46h-6.75c-.931 0-1.687-.704-1.687-1.574V1.672c0-.87.756-1.575 1.688-1.575h6.75z"
							fill="#8A94A6"
							fillRule="nonzero"
						/>
					</svg>
				</FastTooltip>
			</div>
		);

	return (
		<div className={styles.container}>
			<FastTooltip
				isVisible={true}
				i18nKey="copyText"
				onClick={copyTextToClipboard}
				childrenHover={
					<svg width="12" height="15" xmlns="http://www.w3.org/2000/svg">
						<path
							d="m1.127 1.936-.002 1.486v7.35c0 1.257 1.091 2.275 2.438 2.275h6.461c-.238.63-.876 1.05-1.591 1.05h-4.87C1.595 14.097 0 12.61 0 10.773V3.422c0-.686.47-1.27 1.127-1.486zM10.312.097c.448 0 .877.166 1.194.461.316.296.494.696.494 1.114v9.098c0 .418-.178.818-.494 1.114a1.75 1.75 0 0 1-1.194.46h-6.75c-.931 0-1.687-.704-1.687-1.574V1.672c0-.87.756-1.575 1.688-1.575h6.75z"
							fill="#323B4B"
							fillRule="nonzero"
						/>
					</svg>
				}
			>
				<svg width="12" height="15" viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg">
					<path
						d="m1.127 1.936-.002 1.486v7.35c0 1.257 1.091 2.275 2.438 2.275h6.461c-.238.63-.876 1.05-1.591 1.05h-4.87C1.595 14.097 0 12.61 0 10.773V3.422c0-.686.47-1.27 1.127-1.486zM10.312.097c.448 0 .877.166 1.194.461.316.296.494.696.494 1.114v9.098c0 .418-.178.818-.494 1.114a1.75 1.75 0 0 1-1.194.46h-6.75c-.931 0-1.687-.704-1.687-1.574V1.672c0-.87.756-1.575 1.688-1.575h6.75z"
						fill="#8A94A6"
						fillRule="nonzero"
					/>
				</svg>
			</FastTooltip>
		</div>
	);
};
