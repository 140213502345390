import "./index.scss";
import "./font-face.scss";
import "react-toastify/dist/ReactToastify.css";

import { ClerkProvider } from "@clerk/clerk-react";
import { caES, csCZ, daDK, deDE, enUS, esES, frFR, huHU, itIT, jaJP, nlBE, nlNL, plPL, ptPT, roRO, skSK, svSE, ukUA } from "@clerk/localizations";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider, useTranslation } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";

import { GeneralLoader } from "../src/components/atoms/_Pure/GeneralLoader/GeneralLoader";
import { CookieModal } from "./components/atoms/CookieModal/CookieModal";
import i18n from "./i18n";
import { MyRouter } from "./Router";
import { AppStateProvider } from "./state/AppContext";
import { ChatProvider } from "./state/ChatContext";
import { LanguageProvider, useLanguage } from "./state/LanguageContext";
import { isLocalhost } from "./utils/isLocalHost";
import { localStorageHelper } from "./utils/localStorageHelper";

const storedLanguage = localStorageHelper.languageSystem.get();
if (storedLanguage) {
	i18n.changeLanguage(storedLanguage);
}

if (!import.meta.env.VITE_DATADOG_APPLICATION_ID) {
	console.error("Datadog Application ID is not set in environment variables");
}

datadogRum.init({
	applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
	clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
	site: "datadoghq.eu",
	service: "william-frontend", // Replace with your service name
	env: import.meta.env.VITE_DATADOG_ENV, // Your environment, e.g., 'production', 'staging'
	defaultPrivacyLevel: "mask-user-input", // Optional: Adjust privacy level
	sessionSampleRate: 100, // Optional: Sample rate for session data
	sessionReplaySampleRate: 100, // Optional: Sample rate for session replay
	trackUserInteractions: true, // Optional: Record user interactions
	trackResources: true, // Optional: Record resource timing information
	trackLongTasks: true, // Optional: Record long tasks
	traceSampleRate: 100, // Optional: Sample rate for traces
});

datadogLogs.init({
	clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
	site: "datadoghq.eu",
	forwardErrorsToLogs: true,
	env: import.meta.env.VITE_DATADOG_ENV,
	sessionSampleRate: 100,
});

export default function App() {
	const [isLoading, setIsLoading] = useState(true);

	const { t } = useTranslation();

	const clarityError = t("clarity");

	useEffect(() => {
		const loadApp = async () => {
			if (!isLocalhost) {
				const clarityKey = import.meta.env.VITE_CLARITY_KEY;
				if (clarityKey) {
					clarity.init(clarityKey);
					//console.log("Clarity initialized");
				} else {
					//console.error(clarityError);
				}

				// Start the Datadog RUM session
				datadogRum.startSessionReplayRecording({
					force: true,
				});
			} else {
				//console.warn("Clarity not initialized on localhost");
			}

			await new Promise((resolve) => setTimeout(resolve, 1000));
			setIsLoading(false);
		};

		loadApp();
	}, []);

	const { language } = useLanguage();

	if (isLoading) return <GeneralLoader />;

	const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

	const getCurrentClerk = (code: string) => {
		switch (code) {
			case "de":
				return deDE;
			case "fr":
				return frFR;
			case "es":
				return esES;
			case "it":
				return itIT;
			case "nl":
				return nlNL;
			case "pl":
				return plPL;
			case "uk":
				return ukUA;
			case "ro":
				return roRO;
			case "hu":
				return huHU;
			case "pt":
				return ptPT;
			case "sv":
				return svSE;
			case "nl-BE":
				return nlBE;
			case "cs":
				return csCZ;
			// case "sr":
			// 	return srRS;
			case "sk":
				return skSK;
			// case "hr":
			// 	return hrHR;
			case "ca":
				return caES;
			case "da":
				return daDK;
			// case "no":
			// 	return noNO;
			// case "lt":
			// 	return ltLT;
			// case "lv":
			// 	return lvLV;
			// case "mo":
			// 	return moMD;
			// case "et":
			// 	return etEE;
			// case "sl":
			// 	return slSI;
			// case "fi":
			// 	return fiFI;
			case "ja":
				return jaJP;
			default:
				return enUS;
		}
	};

	const clerkLanguage = getCurrentClerk(language.code);
	return (
		<>
			<ClerkProvider publishableKey={PUBLISHABLE_KEY ?? ""} localization={clerkLanguage}>
				<CookieModal />
				<RecoilRoot>
					<AppStateProvider>
						<ChatProvider>
							<ToastContainer />
							<MyRouter />
						</ChatProvider>
					</AppStateProvider>
				</RecoilRoot>
			</ClerkProvider>
		</>
	);
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<LanguageProvider>
				<App />
			</LanguageProvider>
		</I18nextProvider>
	</React.StrictMode>
);

if ("serviceWorker" in navigator) {
	window.addEventListener("load", () => {
		navigator.serviceWorker
			.register("/sw.js")
			.then((registration) => {
				console.log("Service Worker registered with scope:", registration.scope);
			})
			.catch((error) => {
				console.error("Service Worker registration failed:", error);
			});
	});
}
