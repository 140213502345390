import BugShield from "./BugShield.svg";
import Close from "./Close.svg";
import Copy from "./Copy.svg";
import Loader from "./Loader.gif";
import LogoOnlyW from "./LogoOnlyW.svg";
import Paper from "./Paper.svg";
import Refresh from "./Refresh.svg";
import Remove from "./Remove.svg";
import Search from "./Search.svg";
import Send from "./Send.svg";
import Stars1 from "./Stars.svg";
import WilliamLogoW from "./WilliamLogoW.svg";

export const NewChat = (isHover: boolean) => {
	return (
		<svg width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.5 0C17.851 0 23 5.148 23 11.499c0 .302-.012.601-.035.897a7.475 7.475 0 0 0-10.568 10.567 11.455 11.455 0 0 1-6.239-1.278l-4.933 1.284a.978.978 0 0 1-1.19-1.19l1.283-4.932A11.451 11.451 0 0 1 0 11.5C0 5.149 5.149 0 11.5 0zm6.325 12.649c.429 0 .776.347.776.776v3.622h3.623a.776.776 0 0 1 0 1.553l-3.623-.001v3.623a.776.776 0 1 1-1.552 0l-.001-3.623h-3.622a.776.776 0 1 1 0-1.552h3.622v-3.622c0-.429.348-.776.777-.776z"
				fill={isHover ? "#323B4B" : "#8A94A6"}
				fillRule="nonzero"
			/>
		</svg>
	);
};

export const WilliamLogo = ({ className }: { className: string }) => {
	return <img src={WilliamLogoW} alt={"icons.logo"} className={className} style={{ width: "90px", height: "60px" }} />;
};
export const LogoOnlyWIcon = () => {
	return <img src={LogoOnlyW} alt={"icons.logo"} style={{ width: 45, height: 30 }} />;
};

export const PaperIcon = ({ height = 24, className }: { height?: number; className?: string }) => {
	return <img className={className} src={Paper} style={{ height }} alt={"icons.paper"} />;
};

export const RefreshIcon = ({ height = 24 }: { height?: number }) => {
	return <img src={Refresh} style={{ height }} alt={"icons.refresh"} />;
};

export const RemoveIcon = ({ height = 24 }: { height?: number }) => {
	return <img src={Remove} style={{ height }} alt={"icons.remove"} />;
};

export const SearchIcon = ({ height = 24 }: { height?: number }) => {
	return <img src={Search} style={{ height }} alt={"icons.search"} />;
};

export const LoaderGif = () => {
	return <img src={Loader} style={{ height: 100 }} alt={"icons.loader"} />;
};

export const SendIcon = ({ height = 24 }: { height?: number }) => {
	return <img src={Send} style={{ height }} alt={"icons.send"} />;
};

export const CloseIcon = ({ height = 24 }: { height?: number }) => {
	return <img src={Close} style={{ height }} alt={"icons.close"} />;
};

export const BugShieldIcon = ({ height = 24 }: { height?: number }) => {
	return <img src={BugShield} style={{ height }} alt={"icons.bugShield"} />;
};

export const CopyIcon = () => {
	return <img src={Copy} style={{ height: 24 }} alt={"icons.copy"} />;
};

export const PremiumStarsIcon = () => {
	return <img src={Stars1} style={{ height: 12 }} alt={"Premium"} />;
};

export const ChatDialogIcon = () => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
		<svg width="27" height="25" viewBox="0 0 27 25" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<defs>
				<filter x="-9.1%" y="-9.8%" width="118.2%" height="119.5%" filterUnits="objectBoundingBox" id="o8fovpzwqb">
					<feGaussianBlur stdDeviation="1" in="SourceAlpha" result="shadowBlurInner1" />
					<feOffset dy="-2" in="shadowBlurInner1" result="shadowOffsetInner1" />
					<feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0.631372549 0 0 0 0 0.874509804 0 0 0 0.15 0" in="shadowInnerInner1" />
				</filter>
				<filter x="-71.6%" y="-76.8%" width="243.2%" height="253.6%" filterUnits="objectBoundingBox" id="wif1cwde5c">
					<feGaussianBlur stdDeviation="6" in="SourceGraphic" />
				</filter>
				<filter x="-79.6%" y="-85.4%" width="259.1%" height="270.7%" filterUnits="objectBoundingBox" id="ln28a19plg">
					<feGaussianBlur stdDeviation="1" in="SourceAlpha" result="shadowBlurInner1" />
					<feOffset dx="2" dy="2" in="shadowBlurInner1" result="shadowOffsetInner1" />
					<feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0.631372549 0 0 0 0 0.874509804 0 0 0 0.455930056 0" in="shadowInnerInner1" />
				</filter>
				<filter x="-9.1%" y="-9.8%" width="118.2%" height="119.5%" filterUnits="objectBoundingBox" id="xzwxocl4ti">
					<feGaussianBlur stdDeviation="1" in="SourceAlpha" result="shadowBlurInner1" />
					<feOffset dy="-2" in="shadowBlurInner1" result="shadowOffsetInner1" />
					<feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0.631372549 0 0 0 0 0.874509804 0 0 0 0.15 0" in="shadowInnerInner1" />
				</filter>
				<filter x="-29.2%" y="-15.3%" width="216.7%" height="161%" filterUnits="objectBoundingBox" id="ifo0btc8hj">
					<feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0.631372549 0 0 0 0 0.874509804 0 0 0 0.38 0" in="shadowBlurOuter1" />
				</filter>
				<filter x="-29.2%" y="-15.3%" width="216.7%" height="161%" filterUnits="objectBoundingBox" id="ekgw4k2xol">
					<feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0.631372549 0 0 0 0 0.874509804 0 0 0 0.38 0" in="shadowBlurOuter1" />
				</filter>
				<path
					d="M25.143 11.25c0 6.215-5.628 11.253-12.57 11.253-1.512 0-2.959-.24-4.304-.686-.675.796-1.972 1.612-4.454 1.612 0 0 .895-1.94.611-3.61C1.716 17.753 0 14.683 0 11.248 0 5.04 5.631 0 12.573 0s12.57 5.038 12.57 11.25z"
					id="x5ir8xj59a"
				/>
				<path
					d="M25.14 11.25c0 6.216-5.627 11.254-12.568 11.254-1.512 0-2.96-.24-4.304-.685-.675.796-1.972 1.611-4.453 1.611 0 0 .894-1.939.61-3.61C1.717 17.754 0 14.684 0 11.25 0 5.04 5.63 0 12.572 0c6.94 0 12.568 5.039 12.568 11.25z"
					id="hn682ccq3f"
				/>
				<path
					d="M25.143 11.25c0 6.215-5.628 11.253-12.57 11.253-1.512 0-2.959-.24-4.304-.686-.675.796-1.972 1.612-4.454 1.612 0 0 .895-1.94.611-3.61C1.716 17.753 0 14.683 0 11.248 0 5.04 5.631 0 12.573 0s12.57 5.038 12.57 11.25z"
					id="bi3mn7doah"
				/>
				<path
					d="M2.41 0 3.43.787c-1.087.72-1.935 1.997-2.003 3.046.034-.033.271-.065.373-.065.781 0 1.358.589 1.358 1.375 0 .753-.611 1.41-1.426 1.41C.815 6.552 0 5.798 0 4.454 0 2.621 1.12.917 2.41 0"
					id="m3j51e6jok"
				/>
				<path
					d="M7.363 0 8.38.787c-1.086.72-1.935 1.997-2.003 3.046.034-.033.238-.065.374-.065.78 0 1.357.589 1.357 1.375 0 .753-.61 1.41-1.46 1.41-.916 0-1.697-.754-1.697-2.098C4.952 2.621 6.04.917 7.362 0"
					id="5fhkit1w7m"
				/>
				<linearGradient x1="21.464%" y1="13.433%" x2="85.968%" y2="84.937%" id="egsh5tqmge">
					<stop stopColor="#00A1DF" stopOpacity="0" offset="0%" />
					<stop stopColor="#00A1DF" offset="35.831%" />
					<stop stopColor="#542683" offset="65.049%" />
					<stop stopColor="#542683" stopOpacity="0" offset="100%" />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(1 1)">
					<mask id="1b9nqtqfzd" fill="#fff">
						<use xlinkHref="#x5ir8xj59a" />
					</mask>
					<use fill="#D1F2FF" style={{ mixBlendMode: "multiply" }} xlinkHref="#x5ir8xj59a" />
					<use fill="#000" filter="url(#o8fovpzwqb)" xlinkHref="#x5ir8xj59a" />
					<use strokeOpacity=".274" stroke="#FFF" strokeWidth=".572" xlinkHref="#x5ir8xj59a" />
					<g filter="url(#wif1cwde5c)" mask="url(#1b9nqtqfzd)">
						<use fill="url(#egsh5tqmge)" xlinkHref="#hn682ccq3f" />
						<use fill="#000" filter="url(#ln28a19plg)" xlinkHref="#hn682ccq3f" />
					</g>
				</g>
				<g transform="translate(1 1)">
					<use fill="#D1F2FF" style={{ mixBlendMode: "multiply" }} xlinkHref="#bi3mn7doah" />
					<use fill="#000" filter="url(#xzwxocl4ti)" xlinkHref="#bi3mn7doah" />
					<use strokeOpacity=".274" stroke="#FFF" strokeWidth=".572" xlinkHref="#bi3mn7doah" />
				</g>
				<g transform="translate(9.381 8.743)">
					<use fill="#000" filter="url(#ifo0btc8hj)" xlinkHref="#m3j51e6jok" />
					<use fill="#FFF" xlinkHref="#m3j51e6jok" />
				</g>
				<g transform="translate(9.381 8.743)">
					<use fill="#000" filter="url(#ekgw4k2xol)" xlinkHref="#5fhkit1w7m" />
					<use fill="#FFF" xlinkHref="#5fhkit1w7m" />
				</g>
			</g>
		</svg>
	);
};

export const CheckmarkIcon = ({ fill, height }: { height?: string; fill?: string }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0.51133 0.499635 19.99 15" height={height ?? "24"} width="14" fill={fill ?? "#f2f2f2"}>
			<path d="M20.047.925a1.5 1.5 0 0 1 .028 2.121l-11.687 12a1.5 1.5 0 0 1-2.149 0L.925 9.592a1.5 1.5 0 0 1 2.149-2.093l4.238 4.352L17.925.953a1.5 1.5 0 0 1 2.121-.028z" />
		</svg>
	);
};

export const WilliamShieldIcon = () => {
	return (
		<svg width="95" height="102" viewBox="0 0 95 102" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<defs>
				<filter x="-8.6%" y="-3.4%" width="117.3%" height="107.7%" filterUnits="objectBoundingBox" id="5jha7snm5a">
					<feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
					<feMerge>
						<feMergeNode in="shadowMatrixOuter1" />
						<feMergeNode in="SourceGraphic" />
					</feMerge>
				</filter>
				<filter x="-8.2%" y="-7.4%" width="116.3%" height="114.6%" filterUnits="objectBoundingBox" id="rd2sncxh4c">
					<feGaussianBlur stdDeviation="2" in="SourceAlpha" result="shadowBlurInner1" />
					<feOffset dy="-4" in="shadowBlurInner1" result="shadowOffsetInner1" />
					<feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0.631372549 0 0 0 0 0.874509804 0 0 0 0.15 0" in="shadowInnerInner1" />
				</filter>
				<filter x="-71.5%" y="-63.2%" width="243%" height="226.3%" filterUnits="objectBoundingBox" id="tb12u8m90d">
					<feGaussianBlur stdDeviation="12.632" in="SourceGraphic" />
				</filter>
				<filter x="-79%" y="-69.8%" width="258.1%" height="239.6%" filterUnits="objectBoundingBox" id="5oue7isc0h">
					<feGaussianBlur stdDeviation="2" in="SourceAlpha" result="shadowBlurInner1" />
					<feOffset dx="4" dy="4" in="shadowBlurInner1" result="shadowOffsetInner1" />
					<feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0.631372549 0 0 0 0 0.874509804 0 0 0 0.455930056 0" in="shadowInnerInner1" />
				</filter>
				<filter x="-8.2%" y="-7.4%" width="116.3%" height="114.6%" filterUnits="objectBoundingBox" id="1kfsxdkk6j">
					<feGaussianBlur stdDeviation="2" in="SourceAlpha" result="shadowBlurInner1" />
					<feOffset dy="-4" in="shadowBlurInner1" result="shadowOffsetInner1" />
					<feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0.631372549 0 0 0 0 0.874509804 0 0 0 0.15 0" in="shadowInnerInner1" />
				</filter>
				<filter x="-26%" y="-23.9%" width="162.5%" height="195.5%" filterUnits="objectBoundingBox" id="6x8xdq57kk">
					<feOffset dx="1" dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0.631372549 0 0 0 0 0.874509804 0 0 0 0.38 0" in="shadowBlurOuter1" />
				</filter>
				<path
					d="M26.5 0C14.547 11.268 0 11.268 0 11.268.644 54.777 26.5 60 26.5 60S52.358 54.777 53 11.268c0 0-14.543 0-26.5-11.268z"
					id="b8i8y19ksb"
				/>
				<path
					d="M26.47 0C14.546 11.236 0 11.197 0 11.197.765 54.707 26.636 60 26.636 60S52.478 54.846 53 11.339c0 0-14.543-.04-26.53-11.339z"
					id="i8j062eijg"
				/>
				<path
					d="M26.5 0C14.547 11.268 0 11.268 0 11.268.644 54.777 26.5 60 26.5 60S52.358 54.777 53 11.268c0 0-14.543 0-26.5-11.268z"
					id="13x2aaonti"
				/>
				<path id="i3n52ji52l" d="M16.58 0h2.635l-4.47 12.567h-2.226L9.616 4.243l-2.938 8.324H4.452L0 0h2.814L5.68 8.681 8.726 0h1.941l2.938 8.77z" />
				<linearGradient x1="27.734%" y1="7.901%" x2="78.065%" y2="90.223%" id="94jolyzocf">
					<stop stopColor="#00A1DF" stopOpacity="0" offset="0%" />
					<stop stopColor="#00A1DF" offset="35.831%" />
					<stop stopColor="#542683" offset="65.049%" />
					<stop stopColor="#542683" stopOpacity="0" offset="100%" />
				</linearGradient>
			</defs>
			<g filter="url(#5jha7snm5a)" transform="translate(-138 -5)" fill="none" fillRule="evenodd">
				<g transform="translate(159 22)">
					<mask id="9dg3x3g4je" fill="#fff">
						<use xlinkHref="#b8i8y19ksb" />
					</mask>
					<use fill="#D1F2FF" style={{ mixBlendMode: "multiply" }} xlinkHref="#b8i8y19ksb" />
					<use fill="#000" filter="url(#rd2sncxh4c)" xlinkHref="#b8i8y19ksb" />
					<use strokeOpacity=".274" stroke="#FFF" strokeWidth=".644" xlinkHref="#b8i8y19ksb" />
					<g filter="url(#tb12u8m90d)" mask="url(#9dg3x3g4je)">
						<use fill="url(#94jolyzocf)" xlinkHref="#i8j062eijg" />
						<use fill="#000" filter="url(#5oue7isc0h)" xlinkHref="#i8j062eijg" />
					</g>
				</g>
				<g transform="translate(159 22)">
					<use fill="#D1F2FF" style={{ mixBlendMode: "multiply" }} xlinkHref="#13x2aaonti" />
					<use fill="#000" filter="url(#1kfsxdkk6j)" xlinkHref="#13x2aaonti" />
					<use strokeOpacity=".274" stroke="#FFF" strokeWidth=".644" xlinkHref="#13x2aaonti" />
				</g>
				<g transform="translate(175.893 47.328)" fillRule="nonzero">
					<use fill="#000" filter="url(#6x8xdq57kk)" xlinkHref="#i3n52ji52l" />
					<use fill="#FFF" xlinkHref="#i3n52ji52l" />
				</g>
			</g>
		</svg>
	);
};

export const StarsIcon = ({ width = 75 }: { width?: number }) => (
	<svg width={width} height={width} viewBox="0 0 75 75" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="91ii3wmj6a">
				<stop stopColor="#00A1DF" offset="0%" />
				<stop stopColor="#542683" offset="100%" />
			</linearGradient>
			<filter x="-8.6%" y="-3.4%" width="117.3%" height="107.7%" filterUnits="objectBoundingBox" id="gat6xd5gzb">
				<feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
				<feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
				<feMerge>
					<feMergeNode in="shadowMatrixOuter1" />
					<feMergeNode in="SourceGraphic" />
				</feMerge>
			</filter>
		</defs>
		<g filter="url(#gat6xd5gzb)" transform="translate(-35 -173)" fill="url(#91ii3wmj6a)" fillRule="evenodd">
			<path d="M77.885 189A16.651 16.651 0 0 0 90 201.115a16.651 16.651 0 0 0-12.115 12.116 16.651 16.651 0 0 0-12.116-12.116A16.651 16.651 0 0 0 77.885 189zM62.404 209.192a10.176 10.176 0 0 0 7.404 7.404A10.176 10.176 0 0 0 62.404 224 10.176 10.176 0 0 0 55 216.596a10.176 10.176 0 0 0 7.404-7.404z" />
		</g>
	</svg>
);

export const CheckboxInCircle = () => (
	<svg style={{ margin: "-25px -20px -30px -20px", minWidth: 58 }} width="58" height="58" viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="mn2d2xweub">
				<stop stopColor="#00A1DF" offset="0%" />
				<stop stopColor="#542683" offset="100%" />
			</linearGradient>
			<filter x="-8.6%" y="-3.4%" width="117.3%" height="107.7%" filterUnits="objectBoundingBox" id="cn018z8wwa">
				<feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
				<feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
				<feMerge>
					<feMergeNode in="shadowMatrixOuter1" />
					<feMergeNode in="SourceGraphic" />
				</feMerge>
			</filter>
		</defs>
		<g filter="url(#cn018z8wwa)" transform="translate(-36 -363)" fill="none" fillRule="evenodd">
			<g transform="translate(56.547 379.61)">
				<circle fill="url(#mn2d2xweub)" cx="8.5" cy="8.5" r="8.5" />
				<path
					d="m12.946 5.569.015.015a.568.568 0 0 1-.014.816l-2.645 2.476-2.723 2.55a.58.58 0 0 1-.178.114.58.58 0 0 1-.7-.139L4.014 8.294a.57.57 0 0 1 .071-.815l.019-.015a.581.581 0 0 1 .806.07l2.288 2.644 4.94-4.624a.581.581 0 0 1 .809.015z"
					fill="#FFF"
				/>
			</g>
		</g>
	</svg>
);
