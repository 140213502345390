import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { BugShieldIcon } from "../../../../assets/icons/Icons";
import styles from "./ErrorMessageInChat.module.scss";

type ErrorMessageInChatProps = {
	errorKey?: string;
	actionText?: (errorTanslation: TFunction<"erros", undefined>, style: CSSModuleClasses) => React.ReactElement;
};

export const ErrorMessageInChat = ({ actionText, errorKey }: ErrorMessageInChatProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<BugShieldIcon />
			<p>
				{t(errorKey ?? "unexpectedError")}
				{actionText?.(t, styles)}
			</p>
		</div>
	);
};
