import clsx from "clsx";

import styles from "./SupText.module.scss";

export const SupText = ({ text, onClick }: { text: string; onClick?: () => void }) => {
	return (
		// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<span className={clsx(styles.superscript, onClick && styles.clickable)} onClick={onClick}>
			{text}
		</span>
	);
};
