import type { ReactNode } from "react";

import styles2 from "./Chat2.module.scss";

type ChatInputContainerProps = {
	children: ReactNode;
};

export const ChatInputContainer = ({ children }: ChatInputContainerProps) => {
	return (
		<div className={styles2.chatInputWrapper}>
			<div className={styles2.chatInputContainer}>{children}</div>
		</div>
	);
};
