import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { CloseButton } from "../CloseButton/CloseButton";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import styles from "./AdditionalBox.module.scss";

type AdditionalBoxProps = {
	visible: boolean;
	onClose: () => void;
	children?: React.ReactNode;
	titleKey: string;
	icon?: React.ReactNode | JSX.Element;
	gradientWidth?: string;
	subTitleKey?: string;
};

export const AdditionalBox = ({ onClose, children, visible, icon, titleKey = "", subTitleKey }: AdditionalBoxProps) => {
	const { t } = useTranslation();

	if (!visible) return <div className={clsx(styles.container, visible && styles.visible)} />;

	return (
		<div className={clsx(styles.container, visible && styles.visible)}>
			<div className={clsx(styles.contentContainer)}>
				<div className={styles.sectionTitleContainer}>
					<SectionTitle title={t(titleKey)} icon={icon} />
					<CloseButton onClose={() => onClose()} />
				</div>
				{subTitleKey && <div className={styles.subTitle}>{t(subTitleKey)}</div>}

				{children}
			</div>
		</div>
	);
};
