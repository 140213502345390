import clsx from "clsx";
import type { ReactNode } from "react";

import styles2 from "./Chat2.module.scss";

export const ChatContainer = ({ limitedSpace, children }: { limitedSpace?: boolean; children: ReactNode }) => {
	return (
		<div className={clsx([styles2.chatContainer, limitedSpace && styles2.limitedSpace])} data-ui="ChatContainer">
			{children}
		</div>
	);
};
