import { WilliamLogo } from "../../../../assets/icons/Icons";
import styles from "./GeneralLoader.module.css";

interface LoaderProps {
	text?: string;
}

export const GeneralLoader: React.FC<LoaderProps> = ({ text }: LoaderProps) => {
	return (
		<div className={styles.loaderContainer}>
			<WilliamLogo className={styles.loader} />
			{text && <p>{text}</p>}
		</div>
	);
};
