import { UserButton, useUser } from "@clerk/clerk-react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChatDialogIcon, NewChat } from "../../../assets/icons/Icons";
import { TrialDays } from "../../../constants";
import { useApi } from "../../../hooks/useApi";
import { useChatAccess } from "../../../hooks/useChatAccess";
import paymentStyles from "../../organisms/PaymentInfoModal/PaymentInfoModal.module.scss";
import { Button } from "../_Pure/Button/Button";
import buttonStyles from "../_Pure/Button/Button.module.scss";
import { SectionTitle } from "../_Pure/SectionTitle/SectionTitle";
import { FreeTrialInfo } from "../FreeTrialInfo/FreeTrialInfo";
import { Tooltip, TooltipContentContainer, TooltipTrigger } from "../Tooltip/Tooltip";
import { TooltipContent } from "../Tooltip/TooltipContent/TooltipContent";
import styles from "./ChatTopbar.module.scss";

const DotIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
			<title>{"."}</title>
			<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
		</svg>
	);
};
export const ChatTopbar = ({ onResetChat }: { onResetChat: () => void }) => {
	const { user } = useUser();

	const chatAccess = useChatAccess();

	useEffect(() => {
		const addListenerToUserName = () => {
			const userProfileButton = document.querySelector(".cl-userButtonTrigger") as HTMLButtonElement | null;

			const userProfileLabel = document.querySelector(".cl-userButtonOuterIdentifier") as HTMLButtonElement | null;

			const name = userProfileLabel?.textContent;

			const span = document.createElement("span");
			span.textContent = name ?? "";
			span.classList.add("cl-userButtonOuterIdentifier");

			userProfileButton?.appendChild(span);

			userProfileLabel?.remove();
		};

		const isRendered = () => {
			const userProfile = document.querySelector(".cl-userButtonTrigger") as HTMLButtonElement | null;

			const res = Boolean(userProfile);

			return res;
		};

		const modifyUserLabel = () => {
			if (isRendered()) {
				addListenerToUserName();
			} else {
				setTimeout(() => {
					modifyUserLabel();
				}, 100);
			}
		};

		modifyUserLabel();
	}, []);

	const {
		Subscription: { subscriptionCheckoutSession, costumerPortal },
	} = useApi("ChatTopbar");

	const { t } = useTranslation();
	const { t: p } = useTranslation();

	const subscriptionButton = (label: string, onClick: () => void) => (
		<button
			className={paymentStyles.ctaButton}
			style={{ margin: 0 }}
			type="button"
			onClick={async () => {
				onClick?.();
			}}
		>
			{p(label)}
		</button>
	);

	const item = (condition: boolean, text: JSX.Element | string, label: string, onClick: () => void) => {
		if (!condition) return null;

		return (
			<div
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					padding: "20px 10px",
					borderRadius: 10,
				}}
			>
				<p
					style={{
						color: "rgba(0, 0, 0, 0.65)",
						marginBottom: 10,
					}}
				>
					{text}
				</p>
				{subscriptionButton(label, onClick)}
			</div>
		);
	};

	const userPart = (
		<div className={clsx(buttonStyles.container, buttonStyles.primary, styles.userButtonContainer)} id="nonClickableUserButtonContainer">
			<UserButton showName>
				<UserButton.UserProfilePage label={p("subscription")} labelIcon={<DotIcon />} url="terms">
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "2rem",
						}}
					>
						<div style={{ textAlign: "center" }}>
							<h1 style={{ fontWeight: 600, fontSize: "2rem" }}>{p("williamPremium")}</h1>
							<p style={{ color: "rgba(0, 0, 0, 0.65)" }}>{t("manageSubscription")}</p>
						</div>
						{item(
							chatAccess.isFreeTrialActive,
							<>
								{p("itLeft")}
								<b>&nbsp;{TrialDays - chatAccess.daysAfterRegistration}&nbsp;</b>
								{p("daysOfTrial")}
							</>,
							"upgradeToPremium",
							() => {
								subscriptionCheckoutSession().then((url) => {
									window.location.href = url;
								});
							}
						)}
						{item(!chatAccess.hasAccessToChat, p("youDontHaveAccess"), "manageSubscription", () => {
							subscriptionCheckoutSession().then((url) => {
								window.location.href = url;
							});
						})}
						{item(chatAccess.isSubscriptionActive, p("youHaveAccess"), "manageSubscription", () => {
							costumerPortal().then((url) => {
								window.location.href = url;
							});
						})}
					</div>
				</UserButton.UserProfilePage>
			</UserButton>
		</div>
	);

	const [isNewChatHovered, setIsNewChatHovered] = useState(false);

	return (
		<div data-ui="ChatTopbar" className={clsx(styles.container, chatAccess.isSubscriptionActive ? styles.fixH : "")}>
			<div className={clsx(styles.spacing, !!chatAccess.isFreeTrialActive && styles.withContent)}>
				<SectionTitle className={styles.title} title={t("chatWithWilliam")} icon={<ChatDialogIcon />} />

				<div className={styles.divider} />

				<Tooltip placement="left" offset={5}>
					<TooltipTrigger>
						<div onMouseEnter={() => setIsNewChatHovered(true)} onMouseLeave={() => setIsNewChatHovered(false)}>
							<Button
								style={{
									paddingRight: "0",
									paddingLeft: "0",
								}}
								label=""
								onClick={onResetChat}
								icon={NewChat(isNewChatHovered)}
							/>
						</div>
					</TooltipTrigger>
					<TooltipContentContainer>
						<TooltipContent title={t("newChat")} />
					</TooltipContentContainer>
				</Tooltip>

				{user && userPart}
			</div>
			<FreeTrialInfo chatAccess={chatAccess} />
		</div>
	);
};
