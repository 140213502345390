import "./htmlAct.css";

import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { PaperIcon } from "../../../assets/icons/Icons";
import { Gradient } from "../../../components/atoms/_Pure/Gradient/Gradient";
import { SupText } from "../../../components/atoms/_Pure/SupText/SupText";
import { useAppContext } from "../../../state/AppContext";
import type { ActWithIndex } from "../../../state/ChatContext";
import { SectionTitle } from "../../atoms/_Pure/SectionTitle/SectionTitle";
import styles from "./DocumentExplorer.module.scss";
import stylesV2 from "./DocumentExplorerV2.module.scss";

export const DocumentExplorerV2 = () => {
	const {
		state: { acts, currentActGuid },
	} = useAppContext();

	const elementsRef = useRef<{ [key: string]: HTMLElement | null }>({});
	const assignRef = useCallback((elem: HTMLElement | null, guid: string) => {
		if (elem && guid) elementsRef.current[guid] = elem;
	}, []);

	const { t } = useTranslation();

	useEffect(() => {
		if (!currentActGuid) return;

		if (currentActGuid && elementsRef.current[currentActGuid]) {
			const element = elementsRef.current[currentActGuid];
			setTimeout(() => {
				element?.scrollIntoView({ behavior: "smooth" });
				//console.log("scrolling");
			}, 150);
		}
	}, [currentActGuid]);

	return (
		<>
			<Gradient />
			<div className={styles.container}>
				{acts.length > 0 &&
					acts.map((act) => {
						return <SingleAct key={act.guid} act={act} assignRef={assignRef} />;
					})}
			</div>
		</>
	);
};

const SingleAct = ({ act, assignRef }: { act: ActWithIndex; assignRef: (elem: HTMLElement | null, guid: string) => void }) => {
	const sliceContentInToSpansIncludingMostImportant = useCallback((act: ActWithIndex) => {
		if (!act.Content || !act.Most_important_fragment || !act.Content.includes(act.Most_important_fragment)) {
			return <span>{act.Content}</span>;
		}

		const start = act.Content.indexOf(act.Most_important_fragment);
		const end = start + act.Most_important_fragment.length;

		const before = act.Content.slice(0, start);
		const after = act.Content.slice(end);

		const spans = [
			<span key={`${act.guid}-before`}>{before}</span>,
			<span key={`${act.guid}-importantContent`} className={stylesV2.importantContent}>
				{act.Most_important_fragment}
			</span>,
			<span key={`${act.guid}-after`}>{after}</span>,
		];
		return spans;
	}, []);

	let href = act.Source_url ?? "";
	if (act.Most_important_fragment) {
		const sanitizedFragment = encodeURIComponent(act.Most_important_fragment);
		href += "#:~:text=";
		href += sanitizedFragment;
	}

	const { t } = useTranslation();

	let displayedUrl = act.Source_url?.replace("https://", "").replace("http://", "").replace("www.", "") ?? "";
	if (displayedUrl?.length > 50) {
		displayedUrl = `${displayedUrl.slice(0, 55)}...`;
	}

	return (
		<div key={act.guid} id={act.guid ?? ""} className={stylesV2.actContainer} ref={(e) => assignRef(e, act.guid ?? "")}>
			<div className={stylesV2.actTitle}>
				<div className={stylesV2.supContainer}>
					<SupText text={act.index.toString()} />
				</div>
				{act.Title}
			</div>
			<div className={stylesV2.actContent}>{sliceContentInToSpansIncludingMostImportant(act)}</div>
			<div className={stylesV2.actSourceUrl}>
				{t("source")}
				{": "}
				<a href={href} target="_blank" rel="noreferrer">
					{displayedUrl}
				</a>
			</div>
		</div>
	);
};
