import { Outlet } from "react-router-dom";

import styles from "./CenteredLayout.module.css";

export const CenteredLayout = () => {
	return (
		<div className={styles.container}>
			<Outlet />
		</div>
	);
};
