import clsx from "clsx";

import styles from "./Button.module.scss";

export type ButtonProps = {
	label: string;
	icon?: React.ReactNode;
	onClick: () => void;
	variant?: "primary" | "secondary" | "cta";
	disabled?: boolean;
	style?: React.CSSProperties;
};

export const Button = ({ label, icon, onClick, variant = "primary", disabled, style }: ButtonProps) => {
	return (
		<button
			type="button"
			className={clsx([
				styles.container,
				variant === "primary" && styles.primary,
				variant === "secondary" && styles.secondary,
				variant === "cta" && styles.cta,
			])}
			onClick={onClick}
			disabled={disabled}
			style={{ ...style }}
		>
			{icon && <div className={styles.iconContainer}>{icon}</div>}
			{label}
		</button>
	);
};
