import clsx from "clsx";

import styles from "./ProgressBar.module.scss";

type ProgressBarProps = {
	valuePercentage: number;
	background?: "solid" | "linear-gradient";
	showText?: boolean;
};

export const ProgressBar = ({ valuePercentage, background = "solid" }: ProgressBarProps) => {
	return (
		<div title="Progress" className={clsx(styles.wrapper)} role="progressbar" aria-valuemin={0} aria-valuemax={100} aria-valuenow={valuePercentage}>
			<div className={clsx(styles.barContainer)}>
				<div className={clsx(styles.filler)}>
					<div className={clsx(styles.fillerBackground, styles[background])} style={{ width: `${valuePercentage}%` }} />
				</div>
			</div>
		</div>
	);
};

export const ProgressBarFlexible = ({ valuePercentage }: ProgressBarProps) => {
	const fillerFlex = valuePercentage > 0 ? valuePercentage / 100 : 0;

	return (
		<div title="progressbar" className={clsx(styles.wrapper)} role="progressbar" aria-valuemin={0} aria-valuemax={100} aria-valuenow={valuePercentage}>
			<div className={clsx(styles.barContainer)} style={{ flex: fillerFlex }}>
				<div className={clsx(styles.fillerBackground)} style={{ width: `${valuePercentage}%` }} />
			</div>
		</div>
	);
};
