import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

interface InfinityScrollProps<DataType> {
	data: DataType[];
	renderItem: (item: DataType, index: number) => React.ReactElement;
	loadMore: () => void;
	keySelector: (obj: DataType, index: number) => string;
	thresholdInPx?: number;
	loadingElement?: React.ReactElement | string;
}

export const InfinityScroll = <DataType,>({
	data,
	renderItem,
	loadMore,
	keySelector = (_, index) => index.toString(),
	thresholdInPx = 300,
	loadingElement,
}: InfinityScrollProps<DataType>) => {
	const [isFetching, setIsFetching] = useState(false);
	const observer = useRef<IntersectionObserver | null>(null);

	const lastElementRef = useCallback(
		(node: HTMLDivElement | null) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver(
				(entries) => {
					if (entries[0].isIntersecting && !isFetching) {
						setIsFetching(true);
						loadMore();
					}
				},
				{ rootMargin: `${thresholdInPx}px` }
			);
			if (node) observer.current.observe(node);
		},
		[isFetching, thresholdInPx, loadMore]
	);

	useEffect(() => {
		if (isFetching) {
			setIsFetching(false);
		}
	}, [isFetching]);

	return (
		<div>
			{data.map((item, index) => (
				<div key={keySelector(item, index)} ref={index === data.length - 1 ? lastElementRef : null}>
					{renderItem(item, index)}
				</div>
			))}
			{isFetching && <div key="InfinityScrollLoadingElement">{loadingElement}</div>}
		</div>
	);
};
