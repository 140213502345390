import { BaseButton, Dialog, Stack, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import styles from "./Layout.module.css";

const Layout = () => {
	const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
	const [copyClicked, setCopyClicked] = useState<boolean>(false);
	const [copyText, setCopyText] = useState<string>("Copy URL");

	const handleSharePanelDismiss = () => {
		setIsSharePanelOpen(false);
		setCopyClicked(false);
		setCopyText("Copy URL");
	};

	const handleCopyClick = () => {
		navigator.clipboard.writeText(window.location.href);
		setCopyClicked(true);
	};

	useEffect(() => {
		if (copyClicked) {
			setCopyText("Copied URL");
		}
	}, [copyClicked]);

	return (
		<div className={styles.layout}>
			<Outlet />
			<Dialog
				onDismiss={handleSharePanelDismiss}
				hidden={!isSharePanelOpen}
				styles={{
					main: [
						{
							selectors: {
								"@media (min-width: 480px)": {
									maxWidth: "600px",
									background: "#FFFFFF",
									boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
									borderRadius: "8px",
									maxHeight: "200px",
									minHeight: "100px",
								},
							},
						},
					],
				}}
				dialogContentProps={{
					title: "Share the web app",
					showCloseButton: true,
				}}
			>
				<Stack horizontal verticalAlign="center" style={{ gap: "8px" }}>
					<TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly />
					<div
						className={styles.copyButtonContainer}
						role="button"
						tabIndex={0}
						aria-label="Copy"
						onClick={handleCopyClick}
						onKeyDown={(e) => (e.key === "Enter" || e.key === " " ? handleCopyClick() : null)}
					>
						<BaseButton className={styles.copyButton} />
						<span className={styles.copyButtonText}>{copyText}</span>
					</div>
				</Stack>
			</Dialog>
		</div>
	);
};

export default Layout;
