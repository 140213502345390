import { useTranslation } from "react-i18next";
import { ReactTyped } from "react-typed";

import { PrettyLawyer } from "../../../assets/gfx/images";
import styles from "./ChatWelcome.module.scss";

export const ChatWelcome = () => {
	const { t } = useTranslation();
	const h1 = (text: string) => `<h1 className={styles.chatEmptyStateTitle}>${text}</h1>`;
	const h2 = (text: string) => `<h2 className={styles.chatEmptyStateSubtitle}>${text}</h2>`;

	const strings = [h1(t("welcome")) + "<br/>" + h2(t("needHelp"))];

	return (
		<div className={styles.container}>
			<img className={styles.image} src={PrettyLawyer} title={t("lawyer")} />
			<ReactTyped className={styles.chatEmptyStateText} strings={strings} typeSpeed={50} backSpeed={20} backDelay={1000} showCursor={false} />
		</div>
	);
};
