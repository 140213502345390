import { useRef, useState } from "react";

import { convertToMs, type TimeUnit } from "./types";

export interface SpecialThreshold {
	time: TimeUnit;
	message: string;
	afterTimeout?: () => void;
}

interface UseSpecialThresholdsProps {
	specialThresholds: SpecialThreshold[];
	onThresholdReached: (thresholdIndex: number, message: string) => void;
}

export const useSpecialThresholds = ({ specialThresholds, onThresholdReached }: UseSpecialThresholdsProps) => {
	const timeoutRefs = useRef<NodeJS.Timeout[]>([]);
	const startTimeRef = useRef<number | null>(null);
	const lastThresholdIndex = useRef<number>(-1);

	const [isRunning, setIsRunning] = useState<boolean>(false);

	const clearAllTimeouts = () => {
		//console.log("clearing all timeouts");

		timeoutRefs.current.forEach(clearTimeout);
		timeoutRefs.current = [];
		startTimeRef.current = null;
		lastThresholdIndex.current = -1;
		setIsRunning(false);
	};

	const startThresholds = () => {
		//if already running, do nothing
		if (isRunning) return;

		//console.log("starting thresholds");

		startTimeRef.current = Date.now();
		setIsRunning(true);

		for (let i = 0; i < specialThresholds.length; i++) {
			const { time, message, afterTimeout } = specialThresholds[i];
			const timeInMs = convertToMs(time);

			const timeout = setTimeout(() => {
				onThresholdReached(i, message);
				afterTimeout?.();
				lastThresholdIndex.current = i;
			}, timeInMs);
			timeoutRefs.current.push(timeout);
		}
	};

	return {
		currentThreshold: specialThresholds[lastThresholdIndex.current],
		isLastThresholdReached: lastThresholdIndex.current === specialThresholds.length - 1,
		restart: clearAllTimeouts,
		start: startThresholds,
		isRunning,
	};
};
