import { useUser } from "@clerk/clerk-react";
import { useEffect } from "react";

import { localStorageHelper } from "../utils/localStorageHelper";

// this is logic that should runs after user registration
// during registration we store some data in localStorage (beacuse it's hard to pass it to metadata right away)
// and then we update user metadata with this data
// it's a bit of a hack, but it works
export const useRegistrationMetadata = () => {
	const { user } = useUser();

	useEffect(() => {
		if (!user) {
			return;
		}

		const lawFirmName = localStorageHelper.lawFirmName.get();
		const userProfession = localStorageHelper.userProfession.get();
		const marketingCheckbox = localStorageHelper.marketingCheckbox.get();

		if (userProfession || lawFirmName) {
			user?.update({
				unsafeMetadata: {
					...user.unsafeMetadata,
					lawFirmName,
					userProfession,
					marketingCheckbox,
				},
			});

			localStorage.removeItem("lawFirmName");
			localStorage.removeItem("userProfession");
			localStorage.removeItem("marketingCheckbox");
		}
	}, [user]);
};
