import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { LoaderGif } from "../../../assets/icons/Icons";
import { useChatContext } from "../../../state/ChatContext";
import { ProgressBar } from "../_Pure/ProgressBar/ProgressBar";
import styles from "./Loader.module.scss";

export const Loader: React.FC = () => {
	const { currentRandomMessage, currentThresholdMessage, progress, isLoading } = useChatContext();

	const { t } = useTranslation();

	let displayText = "";
	if (currentRandomMessage) {
		displayText = currentRandomMessage;
	}
	if (currentThresholdMessage) {
		displayText = currentThresholdMessage;
	}

	return (
		<div className={clsx([styles.awaitingMessageLoaderContainer, isLoading && styles.visible])}>
			<LoaderGif />
			<ProgressBar valuePercentage={progress} background="linear-gradient" />
			<p className={styles.title}>{displayText}</p>
			<p className={styles.subtitle}>
				{t("loader.progress")}
				{`${progress.toFixed(0)}%`}
			</p>
		</div>
	);
};
