import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useApi } from "../../../hooks/useApi";
import { useChatAccess } from "../../../hooks/useChatAccess";
import { useChatContext } from "../../../state/ChatContext";
import { ChatInput } from "../../atoms/_Pure/ChatInput/ChatInput";
import { ChatTopbar } from "../../atoms/ChatTopbar/ChatTopbar";
import { ChatWelcome } from "../../atoms/ChatWelcome/ChatWelcome";
import { Loader } from "../../atoms/Loader/Loader";
import { AbortButton } from "./AbortButton";
import styles from "./Chat.module.scss";
import { ChatContainer } from "./ChatContainer";
import { ChatInputContainer } from "./ChatInputContainer";
import { MessagesContainer } from "./MessagesContainer";
import { MessageWrapper } from "./MessageWrapper";
import clsx from "clsx";

type ChatProps = {
	limitedSpace?: boolean;
	rerenderChatComponent: () => void;
};

export const Chat = ({ limitedSpace, rerenderChatComponent }: ChatProps) => {
	return <ChatContent limitedSpace={limitedSpace} rerenderChatComponent={rerenderChatComponent} />;
};

const ChatContent = ({ limitedSpace, rerenderChatComponent }: ChatProps) => {
	const { cancelAllRequests } = useApi("Chat");
	const { messages, newChat, isLoading, addMessage, sendAgainLastMessage, riseError } = useChatContext();

	const { hasAccessToChat, isSubscriptionActive } = useChatAccess();

	const { t } = useTranslation();

	const lastMessageRef = useRef<HTMLDivElement>(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: It must trigger when messages.length changes
	useEffect(() => {
		if (lastMessageRef.current) {
			setTimeout(() => {
				lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
			}, 100);
		}
	}, [messages.length]);

	return (
		<>
			<ChatContainer limitedSpace={limitedSpace}>
				<ChatTopbar
					onResetChat={() => {
						newChat();
						rerenderChatComponent();
					}}
				/>

				<div className={clsx(styles.gradient, isSubscriptionActive ? styles.fix : "")} />
				<MessagesContainer>
					{!messages || messages.length < 1 ? (
						<ChatWelcome />
					) : (
						<>
							{messages.map((message, index) => {
								const k = message.id ?? index;
								return (
									<MessageWrapper
										key={`wrapper-message-${k}-${index + 1}`}
										message={message}
										isLastMessage={index === messages.length - 1}
										sendAgainLastMessage={sendAgainLastMessage}
									/>
								);
							})}
						</>
					)}
					{isLoading && <Loader />}
					<div ref={lastMessageRef} />
				</MessagesContainer>

				<ChatInputContainer>
					<ChatInput
						placeholder={t("askMeAnything")}
						disabled={isLoading || !hasAccessToChat}
						onSend={(userMessageInput) => {
							addMessage({
								message: userMessageInput,
								role: "user",
								acts: [],
								id: "",
								parts: [{ Key: `useInput-${messages.length}`, Text: userMessageInput }],
							});
						}}
					/>
					<p className={styles.alphaDisclaimer}>{t("versionDisclaimer")}</p>
				</ChatInputContainer>
				{isLoading ? (
					<AbortButton
						onClick={() => {
							cancelAllRequests();
							riseError();
						}}
					/>
				) : null}
			</ChatContainer>
		</>
	);
};
