import type { ReactElement } from "react";
import Flag from "react-world-flags";

export const TrialDays = 30;
export const ProPrice = 199;

export const API_URL = import.meta.env.VITE_API_URL;

export type languageData = {
	code: string; // 'pl'
	locale: string; // 'pl-PL'
	longNameEng: string; // 'polish'
	longNameOrg: string; // 'polski'
	flagString: string; // '🔥'
	flagSVG: ReactElement; // SVG content
};

export const languages: languageData[] = [
	{
		code: "en",
		locale: "en-US",
		longNameEng: "english",
		longNameOrg: "english",
		flagString: "🇬🇧",
		flagSVG: <Flag code="GBR" />,
	},
	{
		code: "de",
		locale: "de-DE",
		longNameEng: "german",
		longNameOrg: "deutsch",
		flagString: "🇩🇪",
		flagSVG: <Flag code="DEU" />,
	},
	{
		code: "fr",
		locale: "fr-FR",
		longNameEng: "french",
		longNameOrg: "français",
		flagString: "🇫🇷",
		flagSVG: <Flag code="FRA" />,
	},
	{
		code: "es",
		locale: "es-ES",
		longNameEng: "spanish",
		longNameOrg: "español",
		flagString: "🇪🇸",
		flagSVG: <Flag code="ESP" />,
	},
	{
		code: "it",
		locale: "it-IT",
		longNameEng: "italian",
		longNameOrg: "italiano",
		flagString: "🇮🇹",
		flagSVG: <Flag code="ITA" />,
	},
	{
		code: "nl",
		locale: "nl-NL",
		longNameEng: "dutch",
		longNameOrg: "nederlands",
		flagString: "🇳🇱",
		flagSVG: <Flag code="NLD" />,
	},
	{
		code: "pl",
		locale: "pl-PL",
		longNameEng: "polish",
		longNameOrg: "polski",
		flagString: "🇵🇱",
		flagSVG: <Flag code="POL" />,
	},
	{
		code: "uk",
		locale: "uk-UA",
		longNameEng: "ukrainian",
		longNameOrg: "українська",
		flagString: "🇺🇦",
		flagSVG: <Flag code="UKR" />,
	},
	{
		code: "ro",
		locale: "ro-RO",
		longNameEng: "romanian",
		longNameOrg: "română",
		flagString: "🇷🇴",
		flagSVG: <Flag code="ROU" />,
	},
	{
		code: "hu",
		locale: "hu-HU",
		longNameEng: "hungarian",
		longNameOrg: "magyar",
		flagString: "🇭🇺",
		flagSVG: <Flag code="HUN" />,
	},
	{
		code: "pt",
		locale: "pt-PT",
		longNameEng: "portuguese",
		longNameOrg: "português",
		flagString: "🇵🇹",
		flagSVG: <Flag code="PRT" />,
	},
	{
		code: "sv",
		locale: "sv-SE",
		longNameEng: "swedish",
		longNameOrg: "svenska",
		flagString: "🇸🇪",
		flagSVG: <Flag code="SWE" />,
	},
	{
		code: "nl-BE",
		locale: "nl-BE",
		longNameEng: "flemish",
		longNameOrg: "vlaams",
		flagString: "🇧🇪",
		flagSVG: <Flag code="BEL" />,
	},
	{
		code: "cs",
		locale: "cs-CZ",
		longNameEng: "czech",
		longNameOrg: "čeština",
		flagString: "🇨🇿",
		flagSVG: <Flag code="CZE" />,
	},
	{
		code: "sr",
		locale: "sr-RS",
		longNameEng: "serbian",
		longNameOrg: "српски",
		flagString: "🇷🇸",
		flagSVG: <Flag code="SRB" />,
	},
	{
		code: "sk",
		locale: "sk-SK",
		longNameEng: "slovak",
		longNameOrg: "slovenčina",
		flagString: "🇸🇰",
		flagSVG: <Flag code="SVK" />,
	},
	{
		code: "hr",
		locale: "hr-HR",
		longNameEng: "croatian",
		longNameOrg: "hrvatski",
		flagString: "🇭🇷",
		flagSVG: <Flag code="HRV" />,
	},
	{
		code: "da",
		locale: "da-DK",
		longNameEng: "danish",
		longNameOrg: "dansk",
		flagString: "🇩🇰",
		flagSVG: <Flag code="DNK" />,
	},
	{
		code: "no",
		locale: "no-NO",
		longNameEng: "norwegian",
		longNameOrg: "norsk",
		flagString: "🇳🇴",
		flagSVG: <Flag code="NOR" />,
	},
	{
		code: "lt",
		locale: "lt-LT",
		longNameEng: "lithuanian",
		longNameOrg: "lietuvių",
		flagString: "🇱🇹",
		flagSVG: <Flag code="LTU" />,
	},
	{
		code: "lv",
		locale: "lv-LV",
		longNameEng: "latvian",
		longNameOrg: "latviešu",
		flagString: "🇱🇻",
		flagSVG: <Flag code="LVA" />,
	},
	{
		code: "mo",
		locale: "mo-MD",
		longNameEng: "moldovan",
		longNameOrg: "moldovenească",
		flagString: "🇲🇩",
		flagSVG: <Flag code="MDA" />,
	},
	{
		code: "et",
		locale: "et-EE",
		longNameEng: "estonian",
		longNameOrg: "eesti",
		flagString: "🇪🇪",
		flagSVG: <Flag code="EST" />,
	},
	{
		code: "sl",
		locale: "sl-SI",
		longNameEng: "slovenian",
		longNameOrg: "slovenščina",
		flagString: "🇸🇮",
		flagSVG: <Flag code="SVN" />,
	},
	{
		code: "fi",
		locale: "fi-FI",
		longNameEng: "finnish",
		longNameOrg: "suomi",
		flagString: "🇫🇮",
		flagSVG: <Flag code="FIN" />,
	},
	{
		code: "ja",
		locale: "ja-JP",
		longNameEng: "Japanese",
		longNameOrg: "日本語",
		flagString: "🇯🇵",
		flagSVG: <Flag code="JPN" />,
	},
];
