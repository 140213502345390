import type { ReactNode } from "react";

import styles2 from "./Chat2.module.scss";

export const MessagesContainer = ({ children }: { children: ReactNode }) => {
	return (
		<div className={styles2.messagesWrapper} data-ui="MessagesContainer">
			<div className={styles2.messagesContainer}>{children}</div>
		</div>
	);
};
