import { Separator } from "@fluentui/react";
import type { ReactNode } from "react";

import { ErrorMessageInChat } from "../../../components/atoms/_Pure/ErrorMessageInChat/ErrorMessageInChat";
import type { MessageProps } from "../../../components/atoms/Message/Message";
import { Message } from "../../../components/atoms/Message/Message";
import { useApi } from "../../../hooks/useApi";
import type { ChatMessageDto } from "../../../state/ChatContext";
import { isTextPartHTML } from "../../../state/ChatContext";
import { Extra } from "./Extra/Extra";
import { LawReferences } from "./LawReferences";

type MessageWrapperProps = {
	message: ChatMessageDto;
	isLastMessage: boolean;
	sendAgainLastMessage: () => void;
};

export const MessageWrapper = ({ message, isLastMessage, sendAgainLastMessage }: MessageWrapperProps) => {
	const {
		Subscription: { subscriptionCheckoutSession },
	} = useApi("MessageWrapper");

	if (message.role === "subscriptionError") {
		return (
			<ErrorMessageInChat
				errorKey="error401_1"
				actionText={(t, s) => (
					<>
						{", "}
						<button
							type="button"
							className={s.button}
							onClick={() => {
								subscriptionCheckoutSession().then((res) => {
									window.location.href = res;
								});
							}}
						>
							{t("error401_2")}
						</button>
					</>
				)}
			/>
		);
	}
	if (message.role === "error") {
		return (
			<ErrorMessageInChat
				actionText={(t, s) => (
					<>
						&nbsp;
						<button className={s.button} onClick={sendAgainLastMessage} type="button">
							{t("tryAgain2")}
						</button>
						&nbsp;{t("or")}&nbsp;
						<a className={s.button} href="mailto:hello@william.legal?subject=Bug">
							{t("reportError2")}
						</a>
					</>
				)}
			/>
		);
	}

	const msgProps: MessageProps = {
		message: message,
		isLoading: false,
		withFancyBall: isLastMessage,
		origin: message.role,
	};

	if (message.role === "user") {
		msgProps.withFancyBall = false;
	}

	const children: ReactNode[] = [];

	if (message.role === "assistant") {
		children.push(<Separator key={`Separator-${message.id}`} />);
		children.push(<LawReferences key={`LawReferences-${message.id}`} message={message} />);
	}

	if (message.role === "assistant" || message.role === "assistant-NoError") {
		const parts = message.parts.filter((part) => !isTextPartHTML(part));
		const text = parts.map((part) => part.Text).join(" ");
		children.push(<Extra key={`Extra-${message.id}`} message={text} isHtml={false} />);
	}

	return (
		<Message key={message.id} {...msgProps}>
			{children}
		</Message>
	);
};
