import DOMPurify from "dompurify";
import type React from "react";

interface HtmlDisplayProps {
	htmlContent: string;
	className?: string;
}

export const HtmlDisplay: React.FC<HtmlDisplayProps> = ({ className, htmlContent }) => {
	// Sanitize the HTML content
	const sanitizedHtml = DOMPurify.sanitize(htmlContent);

	// biome-ignore lint/security/noDangerouslySetInnerHtml: The HTML is generated by the backend, so it's safe
	return <div className={className} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};
