import { useUser } from "@clerk/clerk-react";
import type { ReactNode } from "react";
import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";

import type { ApiError, ChatHistory, LawyerChatMessage, LawyerChatWithDocumentsResponse, LegalBaseDto } from "../api";
import { intent_detection_type } from "../api";
import { Extra } from "../components/organisms/Chat/Extra/Extra";
import type { TimeUnit } from "../hooks/types";
import { useApi } from "../hooks/useApi";
import { useTimer } from "../hooks/useProgressTimer";
import { type SpecialThreshold, useSpecialThresholds } from "../hooks/useTimeoutThresholds";
import { AppInnerStates, useAppContext } from "./AppContext";

export const TooltipMaxContentLength = 400;
const timer: TimeUnit = { minutes: 1 };

export const guidRegex = /\{([^\s}]+)\}/g; // Matches GUIDs within {} without whitespace

export const htmlRegex = /<html>[\s\S]*?<\/html>/g; // Matches HTML fragments

enum ChatInnerStates {
	NONE = "NONE",
	ERROR = "ERROR",
	STOP_API = "STOP_API",
	NEW_CHAT = "NEW_CHAT",
	ADD_MSG = "ADD_MSG",
	START_TIMER = "START_TIMER",
	API = "API",
	STOP_TIMER = "STOP_TIMER",
	PARSE_RESPOND = "PARSE_RESPOND",
	SET_PROGRESS_MESSAGE = "SET_PROGRESS_MESSAGE",
}

export type Action =
	| { type: ChatInnerStates.NONE }
	| { type: ChatInnerStates.ERROR; payload: ApiError }
	| { type: ChatInnerStates.STOP_API }
	| { type: ChatInnerStates.NEW_CHAT; payload?: ChatHistory }
	| { type: ChatInnerStates.ADD_MSG; payload: ChatMessageDto }
	| { type: ChatInnerStates.START_TIMER }
	| { type: ChatInnerStates.PARSE_RESPOND; payload: LawyerChatWithDocumentsResponse }
	| { type: ChatInnerStates.API }
	| { type: ChatInnerStates.STOP_TIMER }
	| { type: ChatInnerStates.SET_PROGRESS_MESSAGE; payload: string | null };

export type ActWithIndex = LegalBaseDto & { index: number };
export type ReferenceContent = { guid: string; label: string; onReferenceClick: () => void };
export type RoleType = "user" | "assistant" | "assistant-NoError" | "error" | "subscriptionError";

export type TextPartBase = {
	Key: string;
	Text: string | JSX.Element | ReactNode;
};

export type TextPartHTML = TextPartBase & { IsHtml: boolean };
export type TextPartReference = TextPartBase & { Guid: string; Index: number; Title: string; Brief: string };

export type TextPart = TextPartBase | TextPartHTML | TextPartReference;

export function isTextPartHTML(part: TextPart): part is TextPartBase & TextPartHTML {
	return "IsHtml" in part && part.IsHtml === true;
}

export function isTextPartReference(part: TextPart): part is TextPartBase & TextPartReference {
	return "Guid" in part && "Index" in part && "Brief" in part;
}

export type ChatMessageDto = {
	id: string;
	role: RoleType;
	parts: TextPart[];
	message: string;
	acts: ActWithIndex[];
};

interface ChatState {
	messages: ChatMessageDto[];
	isLoading: boolean;
	currentThresholdMessage: string | null;
	currentRandomMessage: string | null;
	pendingMessage: LawyerChatWithDocumentsResponse | null;
	error: ApiError | null;
	innerState: ChatInnerStates;
}

type ChatContextType = {
	messages: ChatMessageDto[];
	isLoading: boolean;
	currentThresholdMessage: string | null;
	currentRandomMessage: string | null;
	lastMessageRef: React.RefObject<HTMLDivElement>;
	progress: number;

	newChat: (history?: ChatHistory) => void;
	sendAgainLastMessage: () => void;
	addMessage: (message: ChatMessageDto) => void;
	addMessageWithoutAPI: (message: ChatMessageDto) => void;
	createMsg: (response: Response) => ChatMessageDto;
	riseError: (withMessage?: boolean, error?: ApiError) => void;
};

export const ChatContext = createContext<ChatContextType | undefined>(undefined);

const initState: ChatState = {
	messages: [],
	isLoading: false,
	currentThresholdMessage: null,
	currentRandomMessage: null,
	pendingMessage: null,
	error: null,
	innerState: ChatInnerStates.NONE,
};

export type Response = {
	id: string;
	text: string;
	acts: LegalBaseDto[];
	userIntent: RoleType;
};

export const parseResponse = ({ id, text, acts, userIntent }: Response): ChatMessageDto => {
	const parts_: TextPart[] = [];
	const acts_: ActWithIndex[] = [];

	let lastIndex = 0;

	// Helper function to add non-special parts
	const addNonSpecialPart = (start: number, end: number) => {
		if (end > start) {
			parts_.push({ Key: `NonSpecial-${start}`, Text: text.substring(start, end) });
		}
	};

	let positiveIndex = 1;

	// Process matches for regex and lambda function on match that returns a TextPart
	const handleRegexMatches = (regex: RegExp, onMatch: (match: RegExpMatchArray, index: number) => number) => {
		let match = regex.exec(text);
		while (match !== null && match.index !== undefined) {
			addNonSpecialPart(lastIndex, match.index);

			positiveIndex = onMatch(match, positiveIndex);

			lastIndex = regex.lastIndex;
			match = regex.exec(text);
		}
	};

	const getActByGuid = (guid: string): LegalBaseDto | undefined => {
		return (acts ?? []).find((act: LegalBaseDto) => act.guid === guid);
	};

	// Extract GUIDs from content
	const guidsIncludedInContent = new Set<string>((text?.match(guidRegex) ?? []).map((match) => match.slice(1, -1)));
	const allGuids = (acts ?? []).map((act) => act.guid);

	// Create GUID to index map
	const guidToIndexMap: Record<string, number> = {};
	let currentIndex = 1;
	for (const guid of guidsIncludedInContent) {
		if (!guidToIndexMap[guid] && allGuids.includes(guid)) {
			guidToIndexMap[guid] = currentIndex++;
		}
	}

	handleRegexMatches(guidRegex, (match, _) => {
		const guid = match[1];
		const guidIndex = guidToIndexMap[guid];

		const part = { Key: `Reference-${guid}-${guidIndex}-${positiveIndex}`, Text: match[0], Guid: guid, Index: guidIndex, Brief: "", Title: "" };
		const act = getActByGuid(guid);

		if (act) {
			const content = act.Content ?? "";
			part.Title = act.Title ?? "";
			part.Brief = content.slice(0, TooltipMaxContentLength) + (content.length > TooltipMaxContentLength ? "..." : "");

			acts_.push({ ...act, index: guidToIndexMap[guid] });
		}
		parts_.push(part);

		return guidIndex;
	});

	handleRegexMatches(htmlRegex, (match, index) => {
		const htmlPart: TextPartHTML = { Key: `Html-${index}`, Text: match[0], IsHtml: true };
		parts_.push(htmlPart);
		return index + 1;
	});

	// Add remaining non-special text
	addNonSpecialPart(lastIndex, text.length);

	const msg: ChatMessageDto = {
		id: id ?? uuid(),
		role: userIntent,
		acts: acts_,
		parts: parts_,
		message: text,
	};

	return msg;
};

export const ChatProvider = ({ children }: { children: ReactNode }) => {
	const { state: appState, dispatch: appDispatch } = useAppContext();

	const {
		cancelAllRequests,
		Chat: { askQuestion },
	} = useApi("ChatPage");

	const lastMessageRef = useRef<HTMLDivElement | null>(null);
	const [shouldDoAfterRenderLogic, setShouldDoAfterRenderLogic] = useState(false);

	const { progress, start, stop, reset } = useTimer(timer);

	const { user } = useUser();

	const historyId = appState.currentChatHistory ?? uuid();

	const { t } = useTranslation();

	const specialThresholds: SpecialThreshold[] = [
		{ time: { seconds: 5 }, message: t("loader.timeout1") },
		{ time: { minutes: 1, seconds: 20 }, message: t("loader.timeout2") },
		{ time: { minutes: 2, seconds: 40 }, message: t("loader.timeout3") },
	];

	const riseError = (withMessage?: boolean, detail?: ApiError) => {
		const error = detail ?? {
			body: "ERROR",
			message: "ERROR",
			name: "ERROR",
			request: {
				method: "HEAD",
				url: "",
			},
			status: 500,
			statusText: "ERROR",
			url: "",
		};

		dispatch({
			type: ChatInnerStates.ERROR,
			payload: error,
		});

		if (withMessage) {
			const errorMsg: ChatMessageDto = parseResponse({
				id: uuid(),
				text: error.message,
				acts: [],
				userIntent: "error",
			});
			if (state.messages.length > 0 && state.messages[state.messages.length - 1].role !== "error") {
				dispatch({ type: ChatInnerStates.ADD_MSG, payload: errorMsg });
			}
		}
	};

	const {
		isLastThresholdReached,
		start: t_start,
		restart: t_restart,
		isRunning,
	} = useSpecialThresholds({
		specialThresholds,
		onThresholdReached: (index, message) => {
			dispatch({
				type: ChatInnerStates.SET_PROGRESS_MESSAGE,
				payload: message,
			});

			if (index === specialThresholds.length - 1) {
				riseError(true, {
					message: message,
					name: "Timeout",
					status: 408,
					body: { errors: message },
					url: "",
					statusText: message,
					request: { url: "", method: "HEAD" },
				});
			}
		},
	});

	const [state, dispatch] = useReducer(
		(state: ChatState, action: Action): ChatState => {
			//console.log("Chat state reducer: ", action);
			switch (action.type) {
				case ChatInnerStates.NEW_CHAT: {
					const id = action.payload?.id ?? uuid();
					appDispatch({ type: AppInnerStates.NEW_CHAT, payload: { id: id } });
					appDispatch({
						type: AppInnerStates.SET_USER_EMAIL,
						payload: { email: action.payload?.userEmail ?? user?.emailAddresses[0]?.emailAddress ?? "" },
					});
					appDispatch({ type: AppInnerStates.SET_CURRENT_ACT, payload: { actGuid: null } });

					let msgs =
						action.payload?.messages?.map((m) =>
							parseResponse({
								id: m.id ?? uuid(),
								text: m.message ?? "",
								acts: m.acts ?? [],
								userIntent: m.role === "user" ? "user" : (m.acts?.length ?? 0) > 0 ? "assistant" : "assistant-NoError",
							})
						) ?? [];

					const removeConsecutiveDuplicates = (messages: ChatMessageDto[]): ChatMessageDto[] => {
						return messages.filter((message, index) => {
							if (index === 0) {
								return true; // Zawsze zachowaj pierwszą wiadomość
							}
							const prevMessage = messages[index - 1];

							// Usuń wiadomości użytkownika, które są takie same
							if (message.role === "user" && prevMessage.role === "user") {
								const areTheSame = message.message === prevMessage.message;
								return !areTheSame;
							}

							// Usuń jakiekolwiek zdublowane wiadomości bota
							const botRoles: RoleType[] = ["assistant", "assistant-NoError"];
							if (botRoles.includes(message.role) && botRoles.includes(prevMessage.role) && message.role === prevMessage.role) {
								return false;
							}

							return true;
						});
					};

					msgs = removeConsecutiveDuplicates(msgs);

					appDispatch({
						type: AppInnerStates.SET_ACTS,
						payload: { msg: msgs[msgs.length - 1]?.acts ?? [] },
					});
					//appDispatch({ type: AppInnerStates.SET_BOX, payload: { boxType: "None" } });

					return { ...initState, messages: msgs ?? [] };
				}
				case ChatInnerStates.ADD_MSG: {
					const msg = action.payload;
					if (!msg.id || msg.id === "") {
						msg.id = uuid();
					}

					const msgs = state.messages;
					const roles: RoleType[] = ["error", "subscriptionError"];
					const lastMsg = msgs.length > 0 ? msgs[msgs.length - 1] : null;

					if (lastMsg && roles.includes(lastMsg.role)) {
						msgs.pop();
					}

					return {
						...state,
						innerState: ChatInnerStates.ADD_MSG,
						messages: [...msgs, { ...msg }],
					};
				}
				case ChatInnerStates.START_TIMER:
					return { ...state, innerState: ChatInnerStates.START_TIMER, isLoading: true };
				case ChatInnerStates.API:
					return { ...state, innerState: ChatInnerStates.API, pendingMessage: null };
				case ChatInnerStates.PARSE_RESPOND:
					return { ...state, innerState: ChatInnerStates.PARSE_RESPOND, pendingMessage: action.payload };
				case ChatInnerStates.ERROR:
					return { ...state, innerState: ChatInnerStates.ERROR, error: action.payload };
				case ChatInnerStates.STOP_API:
					return { ...state, innerState: ChatInnerStates.STOP_API };
				case ChatInnerStates.STOP_TIMER:
					stop();
					reset();
					t_restart();
					return { ...state, innerState: ChatInnerStates.STOP_TIMER, isLoading: false };
				case ChatInnerStates.SET_PROGRESS_MESSAGE:
					return { ...state, currentRandomMessage: action.payload };

				case "NONE":
					return state;
				default:
					return state;
			}
		},
		{ ...initState }
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		//console.log("ChatContext - useEffect of ", state.innerState);
		switch (state.innerState) {
			case ChatInnerStates.ADD_MSG: {
				stop();
				reset();
				setShouldDoAfterRenderLogic(true);
				break;
			}
			case ChatInnerStates.START_TIMER: {
				start();

				dispatch({ type: ChatInnerStates.API });
				break;
			}
			case ChatInnerStates.STOP_TIMER: {
				stop();
				reset();

				t_restart();

				dispatch({ type: ChatInnerStates.SET_PROGRESS_MESSAGE, payload: null });
				break;
			}
			case ChatInnerStates.API: {
				const messages: LawyerChatMessage[] = state.messages.map((message) => ({
					id: message.id,
					role: message.role,
					content: message.message ?? ".",
				}));

				const hId = historyId ?? uuid();
				//console.log("ChatContext - useEffect of API", hId);
				askQuestion({
					id: hId,
					messages: messages,
				})
					.then((response) => {
						if (!response) {
							return;
						}

						dispatch({ type: ChatInnerStates.PARSE_RESPOND, payload: response });
					})
					.catch((error) => {
						dispatch({
							type: ChatInnerStates.ADD_MSG,
							payload: parseResponse({ id: uuid(), text: JSON.stringify(error), acts: [], userIntent: "error" }),
						});
						dispatch({ type: ChatInnerStates.ERROR, payload: error });
					});
				break;
			}
			case ChatInnerStates.STOP_API: {
				cancelAllRequests();
				dispatch({ type: ChatInnerStates.STOP_TIMER });
				break;
			}
			case ChatInnerStates.PARSE_RESPOND: {
				let newMessage: ChatMessageDto = {
					id: state.pendingMessage?.id ?? uuid(),
					role: "error",
					parts: [],
					message: "",
					acts: [],
				};

				if (!state.pendingMessage) break;

				newMessage = parseResponse({
					id: state.pendingMessage.id ?? uuid(),
					text:
						state.pendingMessage.choices?.flatMap((choice) => choice.messages?.map((msg) => msg))?.find((message) => message?.content != null)
							?.content ?? ".",
					acts: state.pendingMessage.acts ?? [],
					userIntent: state.pendingMessage.userIntent === intent_detection_type._0 ? "assistant" : "assistant-NoError",
				});

				appDispatch({ type: AppInnerStates.ADD_TO_CURRENT_HISTORY, payload: [getLastUserMsg(), newMessage] });
				dispatch({ type: ChatInnerStates.ADD_MSG, payload: newMessage });
				dispatch({ type: ChatInnerStates.STOP_TIMER });
				break;
			}
			case ChatInnerStates.ERROR: {
				//alert(JSON.stringify(state.error?.body?.errors ?? state.error?.message ?? state.error));

				if (state.error?.status === 401) {
					dispatch({
						type: ChatInnerStates.ADD_MSG,
						payload: parseResponse({ id: uuid(), text: "", acts: [], userIntent: "subscriptionError" }),
					});
					dispatch({ type: ChatInnerStates.STOP_TIMER });
					dispatch({ type: ChatInnerStates.NONE });
					return;
				}

				//dispatch({ type: ChatInnerStates.STOP_API });
				dispatch({ type: ChatInnerStates.STOP_TIMER });

				dispatch({ type: ChatInnerStates.NONE });

				break;
			}

			//There is no need for additional logic for these states
			case ChatInnerStates.SET_PROGRESS_MESSAGE:
			case ChatInnerStates.NEW_CHAT:
			case ChatInnerStates.NONE:
				break;
			default:
				break;
		}
	}, [state.innerState]);

	const texts = Array.from({ length: 12 }, (_, i) => t(`loader.text${i + 1}`));
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (progress > 0 && progress < 100) {
			const index = Math.floor((progress / 100) * texts.length);
			if (state.currentRandomMessage !== texts[index]) {
				dispatch({ type: ChatInnerStates.SET_PROGRESS_MESSAGE, payload: texts[index] });
			}
		} else if (progress >= 100 && !isRunning) {
			t_start();
		}
	}, [progress, state.currentRandomMessage, texts]);

	useEffect(() => {
		if (isLastThresholdReached && progress > 0) {
			const text = t("loader.timeout3");
			const errorMsg: ApiError = {
				name: "Timeout",
				message: text,
				status: 408,
				body: { errors: text },
				url: "",
				statusText: text,
				request: {
					url: "",
					method: "HEAD",
				},
			};

			dispatch({ type: ChatInnerStates.ERROR, payload: errorMsg });
			dispatch({ type: ChatInnerStates.NONE });
			t_restart();
		}
	}, [isLastThresholdReached, progress, t, t_restart]);

	useLayoutEffect(() => {
		if (shouldDoAfterRenderLogic) {
			lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
			setShouldDoAfterRenderLogic(false);
		}
	}, [shouldDoAfterRenderLogic]);

	const getLastUserMsg = useCallback(() => {
		let lastUserMessage: ChatMessageDto = {} as ChatMessageDto;

		for (let i = state.messages.length - 1; i >= 0; i--) {
			if (state.messages[i].role === "user") {
				lastUserMessage = state.messages[i];
				break;
			}
		}

		return lastUserMessage;
	}, [state.messages]);

	return (
		<ChatContext.Provider
			value={{
				isLoading: state.isLoading,
				messages: state.messages,
				newChat: (h) => {
					const emptyChatHistory: ChatHistory = {
						id: uuid(),
						createdAt: undefined,
						messages: [],
						userEmail: user?.emailAddresses[0]?.emailAddress ?? "",
					};

					dispatch({ type: ChatInnerStates.NEW_CHAT, payload: h ?? emptyChatHistory });
				},
				addMessageWithoutAPI: (message: ChatMessageDto) => {
					dispatch({ type: ChatInnerStates.ADD_MSG, payload: message });
				},
				addMessage: (message: ChatMessageDto) => {
					dispatch({ type: ChatInnerStates.ADD_MSG, payload: message });
					dispatch({ type: ChatInnerStates.STOP_TIMER });
					dispatch({ type: ChatInnerStates.START_TIMER });
				},

				sendAgainLastMessage: () => {
					const lastUserMessage = getLastUserMsg();

					if (lastUserMessage?.message?.trim() !== "") {
						dispatch({ type: ChatInnerStates.STOP_TIMER });
						dispatch({ type: ChatInnerStates.ADD_MSG, payload: lastUserMessage });
						dispatch({ type: ChatInnerStates.START_TIMER });
					}
				},
				lastMessageRef: lastMessageRef,
				currentThresholdMessage: state.currentThresholdMessage,
				currentRandomMessage: state.currentRandomMessage,
				progress: progress,
				createMsg: parseResponse,
				riseError: riseError,
			}}
		>
			{children}
		</ChatContext.Provider>
	);
};

export const useChatContext = () => {
	const context = useContext(ChatContext);
	if (!context) {
		throw new Error("useChatContext must be used within a LanguageProvider");
	}
	return context;
};
