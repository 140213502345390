import { useTranslation } from "react-i18next";

import { Button } from "../../atoms/_Pure/Button/Button";
import styles2 from "./Chat2.module.scss";

export const AbortButton = ({ onClick }: { onClick: () => void }) => {
	const { t } = useTranslation();
	return (
		<div className={styles2.abortButtonContainer}>
			<Button label={t("cancelGenerating")} onClick={onClick} variant="secondary" />
		</div>
	);
};
