import { useCallback, useEffect, useRef, useState } from "react";

import { convertToMs, type TimeUnit } from "./types";

interface UseTimerReturn {
	progress: number;
	start: () => void;
	stop: () => void;
	reset: () => void;
	isRunning: boolean;
}

export const useTimer = (tu: TimeUnit): UseTimerReturn => {
	const [progress, setProgress] = useState(0);
	const startTimeRef = useRef<number | null>(null);
	const timerRef = useRef<NodeJS.Timeout | null>(null);
	const isRunningRef = useRef(false);

	const [durationInMs] = useState(convertToMs(tu));

	const calculateProgress = useCallback(() => {
		if (startTimeRef.current === null) return;
		const elapsed = Date.now() - startTimeRef.current;
		const newProgress = Math.min((elapsed / durationInMs) * 100, 100);
		setProgress(newProgress);
		if (newProgress >= 100) {
			stop();
		}
	}, [durationInMs]);

	const start = useCallback(() => {
		if (isRunningRef.current) return;
		isRunningRef.current = true;
		if (startTimeRef.current === null) {
			startTimeRef.current = Date.now();
		}
		timerRef.current = setInterval(() => {
			calculateProgress();
		}, 100);
	}, [calculateProgress]);

	const stop = useCallback(() => {
		if (!isRunningRef.current) return;
		isRunningRef.current = false;
		if (timerRef.current) {
			clearInterval(timerRef.current);
			timerRef.current = null;
		}
	}, []);

	const reset = useCallback(() => {
		stop();
		setProgress((prev) => {
			//console.log("Resetting progress from", prev, "to 0");
			return 0;
		});
		startTimeRef.current = null;
	}, [stop]);

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (!document.hidden && isRunningRef.current) {
				start();
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			if (timerRef.current) {
				clearInterval(timerRef.current);
			}
		};
	}, [start]);

	return { progress, start, stop, reset, isRunning: isRunningRef.current };
};
