import styles from "./Gradient.module.scss";
type PositionCss = { top?: string; bottom?: string; left?: string; right?: string };
type GradientProps = {
	gradientWidth?: string | number;
	position?: PositionCss;
	height?: string | number;
};

export const Gradient = ({ gradientWidth = "95%", position, height = "50px" }: GradientProps) => {
	const pos: PositionCss = {};

	if (position?.top) {
		pos.top = position.top;
	}
	if (position?.right) {
		pos.right = position.right;
	}
	if (position?.bottom) {
		pos.bottom = position.bottom;
	}
	if (position?.left) {
		pos.left = position.left;
	}

	return <div className={styles.gradient} style={{ ...pos, width: gradientWidth, height: height }} />;
};
