import { useTranslation } from "react-i18next";

import { TrialDays } from "../../../constants";
import type { ChatAccess } from "../../../hooks/useChatAccess";
import { PremiumInfo } from "../_Pure/PremiumInfo/PremiumInfo";
import styles from "./FreeTrialInfo.module.scss";

type FreeTrialInfoProps = {
	chatAccess: ChatAccess;
};

export const FreeTrialInfo = ({ chatAccess: { isFreeTrialActive, daysAfterRegistration, openEarlyModal, isSubscriptionActive } }: FreeTrialInfoProps) => {
	const { t } = useTranslation();

	let days = TrialDays - daysAfterRegistration;
	if (days < 0) {
		days = 0;
	}

	return (
		<div className={styles.container}>
			{!isSubscriptionActive && (
				<div className={styles.textContainer}>
					<div className={styles.firstLine}>
						{t("itLeft2")} {days} {t("daysOfTrial2")}
					</div>
					<div className={styles.secondLine}>
						<PremiumInfo isVisible text={t("goTo")} textDirection="row" fontSize={16} onClick={() => openEarlyModal()} />
					</div>
				</div>
			)}
		</div>
	);
};
