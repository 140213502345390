import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { isLocalhost } from "./utils/isLocalHost";

i18n.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: isLocalhost,
		interpolation: {
			escapeValue: false,
		},
		backend: {
			loadPath: "/locales/{{lng}}/translation.json",
		},
		fallbackLng: {
			dev: ["en"],
			default: ["en"],
		},
		supportedLngs: [
			"en",
			"de",
			"fr",
			"es",
			"it",
			"nl",
			"pl",
			"uk",
			"ro",
			"hu",
			"pt",
			"sv",
			"nl-BE",
			"cs",
			"sr",
			"sk",
			"hr",
			"ca",
			"da",
			"no",
			"lt",
			"lv",
			"mo",
			"et",
			"sl",
			"fi",
			"ja",
		],
		detection: {
			order: ["navigator", "cookie", "querystring", "localStorage", "htmlTag"],
			caches: ["cookie"],
		},
	});

export default i18n;
