import ContentLoader, { type IContentLoaderProps } from "react-content-loader";

interface HistorySkeletonLoader extends IContentLoaderProps {}

export const HistorySkeletonLoader: React.FC<HistorySkeletonLoader> = (props) => (
	<ContentLoader
		speed={2}
		width="100%" // Makes the ContentLoader width responsive
		height={155}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
		<rect x="0" y="0" rx="12" ry="12" width="100%" height="92%" />
		<rect x="0" y="0" rx="12" ry="12" width="100%" height="92%" />
	</ContentLoader>
);
