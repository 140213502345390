import { useTranslation } from "react-i18next";

import { Checkbox } from "../_Pure/Checkbox/Checkbox";
import styles from "./Preferences.module.scss";

interface OptionProps {
	primaryText: string;
	secondaryText: string;
	isChecked: boolean;
	handleToggle?: () => void;
}

const Option: React.FC<OptionProps> = ({ primaryText, secondaryText, isChecked, handleToggle }: OptionProps) => {
	return (
		<div className={styles.optionContainer} onClick={handleToggle}>
			<div>
				<Checkbox isChecked={isChecked} small />
			</div>
			<div>
				<p className={styles.primaryText}>{primaryText}</p>
				<p className={styles.secondaryText}>{secondaryText}</p>
			</div>
		</div>
	);
};

interface PreferencesProps {
	options: {
		necessary: boolean;
		optional: boolean;
	};
	toggleOption: (option: "necessary" | "optional") => void;
}

export const Preferences: React.FC<PreferencesProps> = ({ options, toggleOption }: PreferencesProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.optionsContainer}>
			<Option isChecked={options.necessary} primaryText={t("necessary")} secondaryText={t("necessaryContent")} />
			<Option
				isChecked={options.optional}
				handleToggle={() => toggleOption("optional")}
				primaryText={t("analytics")}
				secondaryText={t("analyticsContent")}
			/>
		</div>
	);
};
