import { useTranslation } from "react-i18next";

import { LawReferenceNotFound } from "../../../components/atoms/LawReferenceNotFound/LawReferenceNotFound";
import { AppInnerStates, useAppContext } from "../../../state/AppContext";
import type { ChatMessageDto } from "../../../state/ChatContext";
import styles from "./Chat.module.scss";
import { LawReference } from "./LawReference/LawReference";

type LawReferencesProps = {
	message: ChatMessageDto;
};

export const LawReferences = ({ message }: LawReferencesProps) => {
	const { t } = useTranslation();

	const { dispatch } = useAppContext();

	if ((!message.acts && message.role !== "assistant-NoError") || (message?.acts?.length ?? 0) <= 0) {
		return <LawReferenceNotFound />;
	}

	function distinctArray<T, T2>(arr: T[], selector: (item: T) => T2): T[] {
		const seen = new Set<T2>();
		return arr.filter((item) => {
			const key = selector(item);
			if (seen.has(key)) {
				return false;
			}
			seen.add(key);
			return true;
		});
	}

	return (
		<>
			<p className={styles.actsGroupTitle}>{t("sources")}</p>
			<div data-ui="LawReferenceContainer" className={styles.lawReferencesContainer}>
				{distinctArray(message.acts, (a) => a.guid).map((act) => (
					<LawReference
						key={act.guid}
						guid={act.guid ?? ""}
						onClick={() => {
							if (!act.guid) return;
							//console.log("lawRefOnClick", act);

							dispatch({ type: AppInnerStates.SET_ACTS, payload: { msg: message.acts } });
							dispatch({
								type: AppInnerStates.SET_CURRENT_ACT,
								payload: { actGuid: act.guid ?? null },
							});

							dispatch({ type: AppInnerStates.SET_BOX, payload: { boxType: "ActsBrowser" } });
						}}
						label={`${act.index}. ${act.Title}`}
					/>
				))}
			</div>
		</>
	);
};
