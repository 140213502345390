import clsx from "clsx";

import styles from "./SideNavLink.module.scss";

type SideNavLinkProps = {
	icon: React.ReactNode;
	onClick: () => void;
	arrowOffset?: number;
	title?: string;
	isActive?: boolean;
};

export const SideNavLink = ({ icon, onClick, arrowOffset, title = "", isActive }: SideNavLinkProps) => {
	return (
		<button
			className={clsx([styles.container, arrowOffset !== undefined && styles.withArrow, isActive && styles.active])}
			title={title}
			onClick={onClick}
			style={
				{
					"--arrow-offset": `${arrowOffset}px`,
				} as React.CSSProperties
			}
		>
			{icon}
		</button>
	);
};
