import clsx from "clsx";

import { useAppContext } from "../../../../state/AppContext";
import { localStorageHelper } from "../../../../utils/localStorageHelper";
import styles from "./LawReference.module.scss";

type LawReferencesProps = {
	guid: string;
	label: string;
	onClick: () => void;
};

export const LawReference = ({ guid, label, onClick }: LawReferencesProps) => {
	const {
		state: { currentActGuid },
	} = useAppContext();

	const isActive = guid === currentActGuid;
	const hasBeenVisited = (localStorageHelper.visitedLinks.get() ?? []).includes(guid ?? "-1");

	const variant = isActive ? "active" : hasBeenVisited ? "visited" : "";

	return (
		// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div
			data-ui="LawReference"
			className={clsx(styles.container, styles[variant])}
			title={label}
			onClick={() => {
				localStorageHelper.visitedLinks.set([...(localStorageHelper.visitedLinks.get() ?? []), guid]);
				onClick();
			}}
		>
			{label}
		</div>
	);
};
