import type { Placement } from "@floating-ui/react";
import {
	autoUpdate,
	flip,
	FloatingPortal,
	offset,
	shift,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useMergeRefs,
	useRole,
	useTransitionStyles,
} from "@floating-ui/react";
import * as React from "react";

interface TooltipOptions {
	initialOpen?: boolean;
	offset?: number;
	placement?: Placement;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
}

export function useTooltip({
	initialOpen = false,
	placement = "top",
	open: controlledOpen,
	onOpenChange: setControlledOpen,
	offset: offsetInPx,
}: TooltipOptions) {
	const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(offsetInPx ?? 5),
			flip({
				crossAxis: placement.includes("-"),
				fallbackAxisSideDirection: "start",
				padding: 5,
			}),
			shift({ padding: 5 }),
		],
	});

	const context = data.context;

	const { styles } = useTransitionStyles(context, {
		duration: 200,
		common: {
			transitionTimingFunction: "ease-in-out",
		},
	});

	const hover = useHover(context, {
		move: false,
		enabled: controlledOpen == null,
	});
	const focus = useFocus(context, {
		enabled: controlledOpen == null,
	});
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "tooltip" });

	const interactions = useInteractions([hover, focus, dismiss, role]);

	return React.useMemo(
		() => ({
			open,
			setOpen,
			...interactions,
			...data,
			styles,
		}),
		[open, setOpen, interactions, data, styles]
	);
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
	const context = React.useContext(TooltipContext);

	if (context == null) {
		throw new Error("Tooltip components must be wrapped in <Tooltip />");
	}

	return context;
};

export function Tooltip({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
	// This can accept any props as options, e.g. `placement`,
	// or other positioning options.
	const tooltip = useTooltip(options);
	return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

export const TooltipTrigger = React.forwardRef<
	HTMLElement,
	React.HTMLProps<HTMLElement> & {
		asChild?: boolean;
		display?: "flex" | "block" | "inline-block";
	}
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
	const context = useTooltipContext();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const childrenRef = (children as any).ref;
	const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

	// `asChild` allows the user to pass any element as the anchor
	if (asChild && React.isValidElement(children)) {
		return React.cloneElement(
			children,
			context.getReferenceProps({
				ref,
				...props,
				...children.props,
				"data-state": context.open ? "open" : "closed",
			})
		);
	}

	return (
		<div
			ref={ref}
			// The user can style the trigger based on the state
			data-state={context.open ? "open" : "closed"}
			{...context.getReferenceProps(props)}
			style={{
				background: "transparent",
				outline: "none",
				display: props.display ?? "inline-block",
			}}
		>
			{children}
		</div>
	);
});

export const TooltipContentContainer = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement> & { style?: React.CSSProperties }>(
	function TooltipContentContainer({ style, ...props }, propRef) {
		const context = useTooltipContext();
		const ref = useMergeRefs([context.refs.setFloating, propRef]);

		let myStyle: React.CSSProperties = {
			...context.floatingStyles,
			...style,
			...context.styles,
			zIndex: context.open ? 1 : -1,
			display: context.open ? "block" : "none",
		};

		if (!context.open) {
			myStyle = { ...myStyle, height: 0 };
		}

		return (
			<FloatingPortal>
				<div ref={ref} style={myStyle} {...context.getFloatingProps(props)} />
			</FloatingPortal>
		);
	}
);
