import { useTranslation } from "react-i18next";

import { ChatDialogIcon, PaperIcon } from "../../../assets/icons/Icons";
import { useChatAccess } from "../../../hooks/useChatAccess";
import { AppInnerStates, useAppContext } from "../../../state/AppContext";
import { useLanguage } from "../../../state/LanguageContext";
import { PremiumInfo } from "../../atoms/_Pure/PremiumInfo/PremiumInfo";
import { Separator } from "../../atoms/_Pure/Separator/Separator";
import { SideNav } from "../../atoms/_Pure/SideNav/SideNav";
import { SideNavGroupTitle } from "../../atoms/_Pure/SideNavGroupTitle/SideNavGroupTitle";
import { SideNavLink } from "../../atoms/_Pure/SideNavLink/SideNavLink";
import { LanguageDropdown } from "../../atoms/LanguageDropdown/LanguageDropdown";
import { Logo } from "../../atoms/Logo/Logo";
import { Tooltip, TooltipContentContainer, TooltipTrigger } from "../../atoms/Tooltip/Tooltip";
import { TooltipContent } from "../../atoms/Tooltip/TooltipContent/TooltipContent";
import styles from "./ChatSideNav.module.scss";

export const ChatSideNav = () => {
	const {
		state: { currentBox, chatHistories },
		dispatch,
	} = useAppContext();

	const { hasAccessToChat, isFreeTrialActive } = useChatAccess();

	const { t } = useTranslation();
	const { language } = useLanguage();
	return (
		<div className={styles.container}>
			<SideNav>
				<Logo alfaBadge />
				<Separator />
				<SideNavGroupTitle />
				<div className={styles.navLinksContainer}>
					<Tooltip placement="right" offset={10}>
						<TooltipTrigger>
							<SideNavLink
								icon={<PaperIcon />}
								onClick={() => {
									if (currentBox !== "ActsBrowser") {
										dispatch({ type: AppInnerStates.SET_BOX, payload: { boxType: "ActsBrowser" } });
									} else {
										dispatch({ type: AppInnerStates.SET_BOX, payload: { boxType: "None" } });
									}
								}}
								title={t("sourceBrowser")}
								isActive={currentBox === "ActsBrowser"}
							/>
						</TooltipTrigger>
						<TooltipContentContainer>
							<TooltipContent title={t("sourceBrowser")} />
						</TooltipContentContainer>
					</Tooltip>

					<Tooltip placement="right" offset={10}>
						<TooltipTrigger>
							<SideNavLink
								icon={<ChatDialogIcon />}
								onClick={() => {
									if (currentBox !== "ChatHistory") {
										dispatch({ type: AppInnerStates.SET_BOX, payload: { boxType: "ChatHistory" } });
									} else {
										dispatch({ type: AppInnerStates.SET_BOX, payload: { boxType: "None" } });
									}

									if (chatHistories.length === 0) {
										dispatch({ type: AppInnerStates.MORE_HISTORY });
									}
								}}
								title={t("historyBrowser")}
								isActive={currentBox === "ChatHistory"}
							/>
						</TooltipTrigger>
						<TooltipContentContainer>
							<TooltipContent title={t("historyBrowser")} />
						</TooltipContentContainer>
					</Tooltip>

					<div className={styles.divider} />

					<PremiumInfo
						text={t("youAre")}
						//isVisible={hasAccessToChat && !isFreeTrialActive}
						isVisible={true}
					/>

					<Tooltip placement="right" offset={10}>
						<TooltipTrigger>
							<LanguageDropdown />
						</TooltipTrigger>
						<TooltipContentContainer>
							<TooltipContent title={`${t("actualLanguage")}: ${language.longNameOrg}`} subtitle={t("clickToChange")} />
						</TooltipContentContainer>
					</Tooltip>
				</div>
			</SideNav>
		</div>
	);
};
