import { useTranslation } from "react-i18next";

import { LogoOnlyWIcon } from "../../../assets/icons/Icons";
import { Tooltip, TooltipContentContainer, TooltipTrigger } from "../Tooltip/Tooltip";
import { TooltipContent } from "../Tooltip/TooltipContent/TooltipContent";
import styles from "./Logo.module.scss";

type LogoProps = {
	alfaBadge?: boolean;
};

export const Logo = ({ alfaBadge }: LogoProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.container}>
			<LogoOnlyWIcon />
			{alfaBadge && (
				<Tooltip placement="right" offset={10}>
					<TooltipTrigger>
						<span className={styles.alfaBadge}>{t("versionLabel")}</span>
					</TooltipTrigger>
					<TooltipContentContainer>
						<TooltipContent title={t("versionTitle")} subtitle={t("versionSubtitle")} />
					</TooltipContentContainer>
				</Tooltip>
			)}
		</div>
	);
};
