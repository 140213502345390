import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CheckboxInCircle, RemoveIcon, StarsIcon, WilliamShieldIcon } from "../../../assets/icons/Icons";
import { CloseButton } from "../../../components/atoms/_Pure/CloseButton/CloseButton";
import { useApi } from "../../../hooks/useApi";
import styles from "./PaymentInfoModal.module.scss";

type PaymentInfoModalProps = { onClose: () => void };

const SubscriptionVariantCard = () => {
	const { t } = useTranslation();
	return (
		<div className={styles.subscriptionVariantCard}>
			<div style={{ transform: "translateX(-20px)", marginBottom: "-15px" }}>
				<StarsIcon />
			</div>

			<p className={styles.variantName}>{t("proLabel")}</p>
			<p className={styles.variantDescription}>{t("variantDescription")}</p>
			<div
				style={{
					width: "70%",
					background: "rgba(151, 151, 151, 0.1)",
					height: 2,
					marginBottom: 13,
				}}
			/>

			<div className={styles.bulletPoint}>
				<CheckboxInCircle /> <span>{t("point.p1")}</span>
			</div>
			<div className={styles.bulletPoint}>
				<CheckboxInCircle />
				<span>{t("point.p2")}</span>
			</div>
			<div className={styles.bulletPoint}>
				<CheckboxInCircle /> <span>{t("point.p3")}</span>
			</div>
		</div>
	);
};

export const PaymentInfoModal = ({ onClose }: PaymentInfoModalProps) => {
	const { t } = useTranslation();

	const {
		Subscription: { subscriptionCheckoutSession },
	} = useApi("PaymentInfoModal");

	const [isClicked, setIsClicked] = useState(false);

	return (
		<div className={clsx([styles.overlay, styles.visible])}>
			<div className={styles.container}>
				<CloseButton onClose={onClose} className={styles.closeButton} />

				<div className={styles.header}>
					<div style={{ marginBottom: "-15px" }}>
						<WilliamShieldIcon />
					</div>
					<h1 className={styles.headerTitle}>{t("noLimit")}</h1>
					<h2 className={styles.headerSubtitle}>{t("planAdvantage")}</h2>
				</div>
				<SubscriptionVariantCard />

				<div
					style={{
						width: "70%",
						background: "rgba(151, 151, 151, 0.1)",
						height: 2,
						marginBottom: 13,
					}}
				/>
				<button
					type="button"
					className={styles.ctaButton}
					onClick={async () => {
						setIsClicked(true);
						const res = subscriptionCheckoutSession();

						res.then((res) => {
							const url = res;
							setIsClicked(false);
							window.location.href = url;
						});
					}}
				>
					{!isClicked && t("payment")}
					{isClicked && <div className={styles.loader} />}
				</button>
			</div>
		</div>
	);
};
