import { useUser } from "@clerk/clerk-react";
import { datadogLogs } from "@datadog/browser-logs";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { PremiumStarsIcon, StarsIcon } from "../../../../assets/icons/Icons";
import { useChatAccess } from "../../../../hooks/useChatAccess";
import styles from "./PremiumInfo.module.scss";

type PremiumInfoProps = {
	isVisible: boolean;
	text?: string;
	textDirection?: "column" | "row";
	fontSize?: number;
	onClick?: () => void;
};

export const PremiumInfo: React.FC<PremiumInfoProps> = ({ isVisible, onClick, text, textDirection, fontSize = 14 }: PremiumInfoProps) => {
	const { t } = useTranslation();

	const { user } = useUser();
	const { daysAfterRegistration } = useChatAccess();

	if (!isVisible) {
		return null;
	}

	let containerDirection = "";
	switch (textDirection) {
		case "column":
			containerDirection = styles.column;
			break;
		case "row":
			containerDirection = styles.row;
			break;
		default:
			containerDirection = styles.column;
			break;
	}

	return (
		<div className={clsx(styles.container, containerDirection)}>
			{text && (
				<span className={styles.text} style={{ fontSize: `${fontSize}px` }}>
					{text}
				</span>
			)}
			<div
				className={clsx(styles.proBadge, onClick ? styles.buttonRole : "")}
				role="button"
				onClick={() => {
					datadogLogs.logger.info("User clicked on PRO button", {
						userId: user?.id,
						userEmail: user?.emailAddresses[0]?.emailAddress,
						daysAfterRegistration: daysAfterRegistration,
					});
					onClick?.();
				}}
				onKeyDown={(e) => {}}
			>
				<PremiumStarsIcon />
				&nbsp;<span className={styles.text}>{t("proLabel")}</span>
			</div>
		</div>
	);
};
