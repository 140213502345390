import styles from "./TooltipContent.module.scss";

interface TooltipContentProps {
	title: string;
	subtitle?: string;
}

export const TooltipContent: React.FC<TooltipContentProps> = ({ title, subtitle }: TooltipContentProps) => {
	return (
		<div className={styles.container}>
			<p className={styles.title}>{title}</p>
			{subtitle && <p className={styles.subtitle}>{subtitle}</p>}
		</div>
	);
};
