import clsx from "clsx";
import type React from "react";

import styles from "./SectionTitle.module.scss";

type SectionTitleProps = {
	title: string;
	icon?: JSX.Element | React.ReactNode;
	className?: string | string[];
};

export const SectionTitle = ({ title, icon, className }: SectionTitleProps) => {
	return (
		<div className={clsx(styles.container, className)}>
			{icon ? icon : <span className={styles.decorator} />}
			<span className={styles.title}>{title}</span>
		</div>
	);
};
