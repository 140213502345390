import clsx from "clsx";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { SendIcon } from "../../../../assets/icons/Icons";
import styles from "./ChatInput.module.scss";

type ChatInputProps = {
	placeholder: string;
	disabled?: boolean;
	onSend: (value: string) => void;
};

export const ChatInput = ({ placeholder, disabled, onSend }: ChatInputProps) => {
	const [value, setValue] = useState<string>("");

	const textareaRef = useRef<HTMLSpanElement>(null);
	const clearInput = () => {
		setValue("");
		if (textareaRef.current) {
			textareaRef.current.textContent = "";
		}
	};

	const handleSubmit = () => {
		if (!value) return;

		onSend(value);
		clearInput();
	};

	/**
	 * Handles the enter key press event.
	 * If the shift + enter is pressed, a new line is created.
	 * If the enter is pressed, the message is sent.
	 */
	const handleEnter = (event: React.KeyboardEvent<HTMLSpanElement>) => {
		const shiftEnter = event.key === "Enter" && event.shiftKey;
		const enter = event.key === "Enter";

		if (!shiftEnter && enter) {
			event.preventDefault();
			handleSubmit();
		}
	};

	const [isFocused, setIsFocused] = useState(false);

	const { t } = useTranslation();

	return (
		<>
			<div className={clsx([styles.container, disabled && styles.disabled, isFocused && styles.focused])}>
				<span
					onFocus={() => {
						setIsFocused(true);
					}}
					onBlur={() => {
						setIsFocused(false);
					}}
					ref={textareaRef}
					style={{ "--placeholder": `"${placeholder}"` } as React.CSSProperties}
					className={styles.textarea}
					role="textbox"
					contentEditable={!disabled}
					onInput={(e) => {
						if (!disabled) {
							setValue(e.currentTarget.textContent ?? "");
						}
					}}
					onPaste={(e) => {
						e.preventDefault();
						const clipboardDataAsText = e.clipboardData.getData("text/plain");
						document.execCommand("insertText", false, clipboardDataAsText);
						setValue(clipboardDataAsText.toString());
					}}
					onKeyDown={handleEnter}
				/>
				<button onClick={handleSubmit} style={{ height: "100%", width: 24, background: "transparent" }} title={t("send")}>
					<SendIcon />
				</button>
			</div>
		</>
	);
};
