import { MouseEventHandler } from "react";

import styles from "./Checkbox.module.scss";

interface CheckboxProps {
	label?: string;
	isChecked: boolean;
	onClick?: MouseEventHandler;
	error?: boolean;
	small?: boolean;
}

const Checkmark = ({ fill }: { fill?: string }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0.51133 0.499635 19.99 15" height="14" width="14" fill={fill ?? "black"}>
			<path d="M20.047.925a1.5 1.5 0 0 1 .028 2.121l-11.687 12a1.5 1.5 0 0 1-2.149 0L.925 9.592a1.5 1.5 0 0 1 2.149-2.093l4.238 4.352L17.925.953a1.5 1.5 0 0 1 2.121-.028z" />
		</svg>
	);
};

export const Checkbox: React.FC<CheckboxProps> = ({ label, isChecked, onClick, small, error }: CheckboxProps) => {
	return (
		<div className={styles.container} onClick={onClick}>
			<div className={[styles.checkbox, error ? styles.error : "", isChecked ? styles.selected : "", small ? styles.small : ""].join(" ")}>
				{isChecked && <Checkmark fill="white" />}
			</div>
			{label && <p className={styles.label}>{label}</p>}
		</div>
	);
};
