interface LocalStorageItem<T = unknown> {
	get: () => T | null;
	set: (value: T) => void;
	remove: () => void;
	hasValue: (value?: T) => boolean;
	isUndefinedOrNull: () => boolean;
}

const localStorageHandler = <T = unknown,>(key: string): LocalStorageItem<T> => {
	if (typeof window === "undefined") {
		return {
			get: () => null,
			set: () => null,
			remove: () => null,
			hasValue: () => false,
			isUndefinedOrNull: () => false,
		};
	}

	const get = (): T | null => {
		try {
			const value = localStorage.getItem(key);
			return value ? JSON.parse(value) : null;
		} catch (error) {
			//console.error(`Error parsing localStorage key "${key}":`, error);
			return null;
		}
	};

	const set = (value: T): void => {
		try {
			localStorage.setItem(key, JSON.stringify(value));
		} catch (error) {
			//console.error(`Error stringifying localStorage key "${key}":`, error);
		}
	};

	const remove = (): void => {
		localStorage.removeItem(key);
	};

	const isUndefinedOrNull = (): boolean => {
		const value = get();
		return value === null || value === undefined;
	};

	const hasValue = (value?: T): boolean => {
		if (value === undefined) {
			return !isUndefinedOrNull();
		}
		return JSON.stringify(get()) === JSON.stringify(value);
	};

	return { get, set, remove, hasValue, isUndefinedOrNull };
};

export const localStorageHelper = {
	isDarkMode: localStorageHandler<boolean>("william_isDarkModeActive"),
	isCookiesApproved: localStorageHandler<boolean>("william_isCookiesApproved"),
	languageSystem: localStorageHandler<string>("william_language"),
	userProfession: localStorageHandler<string>("william_userProfession"),
	marketingCheckbox: localStorageHandler<string>("william_marketingCheckbox"),
	lawFirmName: localStorageHandler<string>("william_lawFirmName"),
	visitedLinks: localStorageHandler<string[]>("william_visitedLinks"),
	hints: localStorageHandler<Record<string, boolean>>("william_hints"),
};
