import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useRedirectsFromStripe = () => {
	const urlParams = new URLSearchParams(window.location.search);

	const redirectedFromSuccessfulPayment = urlParams.has("redirectedFromSucessfullPayment");
	const redirectedFromUnsuccessfulPayment = urlParams.has("redirectedFromUnsucessfullPayment");

	const { t } = useTranslation();

	useEffect(() => {
		if (redirectedFromSuccessfulPayment) {
			window.history.pushState({}, document.title, window.location.pathname);
			toast.success(t("successfulPayment"));
		}
		if (redirectedFromUnsuccessfulPayment) {
			window.history.pushState({}, document.title, window.location.pathname);
			toast.warn(t("failurePayment"));
		}
	}, [redirectedFromSuccessfulPayment, redirectedFromUnsuccessfulPayment]);
};
