interface TimeMs {
	milliseconds: number;
}

interface TimeS {
	seconds: number;
}

interface TimeMin {
	minutes: number;
}

export type TimeUnit = TimeMs | TimeS | TimeMin;

// Type guards
export const isTimeMs = (time: TimeUnit): time is TimeMs => {
	return (time as TimeMs).milliseconds !== undefined;
};

export const isTimeS = (time: TimeUnit): time is TimeS => {
	return (time as TimeS).seconds !== undefined;
};

export const isTimeMin = (time: TimeUnit): time is TimeMin => {
	return (time as TimeMin).minutes !== undefined;
};

export const convertToMs = (time: TimeUnit): number => {
	let totalMs = 0;

	if (isTimeMs(time)) {
		totalMs += time.milliseconds;
	}
	if (isTimeS(time)) {
		totalMs += time.seconds * 1000;
	}
	if (isTimeMin(time)) {
		totalMs += time.minutes * 60 * 1000;
	}

	if (totalMs === 0) throw new Error("Invalid time unit");

	return totalMs;
};
