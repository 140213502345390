import { SignUp } from "@clerk/clerk-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { localStorageHelper } from "../../../utils/localStorageHelper";
import styles from "./CustomSignUp.module.scss";

export const CustomSignUp = () => {
	const { t } = useTranslation();

	const submitButtonDisabled = (value: boolean) => {
		const submitButton: HTMLButtonElement | null = document.querySelector(".cl-formButtonPrimary");

		if (!submitButton) return;
		submitButton.disabled = value;
	};

	const addProfessionSelect = () => {
		const inputContainer = document.createElement("div");
		inputContainer.id = "userProfesionContainer";

		const label = document.createElement("label");
		label.textContent = "Status zawodowy";
		label.className = styles.label;

		const select = document.createElement("select");
		select.id = "userProfession";
		select.name = "userProfession";
		select.className = styles.input;
		select.required = true;
		select.addEventListener("change", (e) => {
			localStorageHelper.userProfession.set((e.target as HTMLInputElement).value);
		});

		const options = [
			t("userType.legalCounsel"),
			t("userType.legalTrainee"),
			t("userType.advocate"),
			t("userType.advocateTrainee"),
			t("userType.lawyer"),
			t("userType.lawStudent"),
			t("userType.taxAdvisor"),
			t("userType.notary"),
			t("userType.notaryTrainee"),
			t("userType.judge"),
			t("userType.judicialTrainee"),
			t("userType.courtBailiff"),
			t("userType.courtBailiffTrainee"),
			t("userType.other"),
		];
		for (const optionText of options) {
			const option = document.createElement("option");
			option.value = optionText;
			option.textContent = optionText;
			select.appendChild(option);
		}

		select.value = "";

		inputContainer.append(label);
		inputContainer.append(select);

		return inputContainer;
	};

	const addLawFirmName = () => {
		const inputContainer = document.createElement("div");
		inputContainer.id = "lawFirmNameContainer";

		const label = document.createElement("label");
		label.textContent = t("lawFirmName");
		label.className = styles.label;
		label.htmlFor = "lawFirmName";

		const input = document.createElement("input");
		input.setAttribute("type", "text");
		input.autocomplete = "off";
		input.id = "lawFirmName";
		input.name = "lawFirmName";
		input.className = styles.input;
		input.addEventListener("change", (e) => {
			localStorageHelper.lawFirmName.set((e.target as HTMLInputElement).value);
		});

		inputContainer.append(label);
		inputContainer.append(input);

		return inputContainer;
	};

	const addTermCheckbox = () => {
		const inputContainer = document.createElement("div");
		inputContainer.className = styles.checkbox;
		inputContainer.id = "termCheckboxContainer";

		const label = document.createElement("label");
		label.style.marginBottom = "3px";
		label.innerHTML = `${t("termCheckboxContent1")} <a class="${styles.link}" target="_blank" href="https://william.legal/terms">${t("termCheckboxContent2")}</a> ${t("and2")} <a target="_blank" class="${styles.link}" href="https://william.legal/privacy">${t("termCheckboxContent3")}</a> ${t("termCheckboxContent4")}*`;
		label.htmlFor = "termCheckbox";

		const input = document.createElement("input");
		input.setAttribute("type", "checkbox");
		input.id = "termCheckbox";
		input.name = "termCheckbox";
		input.addEventListener("change", (e) => {
			const target = e.target as HTMLInputElement;

			submitButtonDisabled(!target?.checked);
		});

		inputContainer.append(input);
		inputContainer.append(label);

		return inputContainer;
	};

	const addMarketingCheckbox = () => {
		const inputContainer = document.createElement("div");
		inputContainer.className = styles.checkbox;
		inputContainer.id = "marketingCheckboxContainer";

		const label = document.createElement("label");
		label.textContent = t("marketingContent");
		label.className = styles.termCheckboxLabel;
		label.htmlFor = "marketingCheckbox";

		const input = document.createElement("input");
		input.setAttribute("type", "checkbox");
		input.id = "marketingCheckbox";
		input.name = "marketingCheckbox";
		input.className = styles.termCheckboxInput;
		input.addEventListener("change", (e) => {
			const target = e.target as HTMLInputElement;

			localStorageHelper.marketingCheckbox.set(String(target.checked));
		});

		inputContainer.append(input);
		inputContainer.append(label);

		return inputContainer;
	};

	const check = () => {
		const form = document.querySelector(".cl-form");

		form?.prepend(addProfessionSelect());

		form?.prepend(addLawFirmName());

		const submitButton = document.querySelector(".cl-formButtonPrimary");
		submitButton?.parentElement?.insertBefore(addTermCheckbox(), submitButton);
		submitButton?.parentElement?.insertBefore(addMarketingCheckbox(), submitButton);
		submitButton?.parentElement?.insertBefore(document.createElement("br"), submitButton);

		const removeAdditionalContainers = () => {
			const form = document.querySelector(".cl-form");
			const userProfessionContainer = document.getElementById("userProfesionContainer");
			const lawFirmNameContainer = document.getElementById("lawFirmNameContainer");

			if (!form || !userProfessionContainer || !lawFirmNameContainer) return;
			form?.removeChild(userProfessionContainer);
			form?.removeChild(lawFirmNameContainer);
		};

		const url = window.location.href;

		if (url.includes("verify-phone-number") || url.includes("verify-email-address")) {
			removeAdditionalContainers();
			return;
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const interval = setInterval(() => {
			const element = document.querySelector(".cl-form");

			if (!element) {
				return;
			}

			check();
			clearInterval(interval);
		}, 100);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className={styles.container}>
			<SignUp signInUrl="/auth/signin" />
		</div>
	);
};
